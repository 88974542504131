
.mobileonly{
    display: none !important;
 }

.tooltiptextd {
    visibility: hidden;
}

/* Show the tooltip text when you mouse over the tooltip container */
.icondiv:hover .tooltiptextd {
  visibility: visible;
}

.mobilenotes{
    display: none;
}



/* Ipad Air potrait  devices */
 
@media only screen   
and (min-width: 786px)   
and (max-width: 1100px)  
{ 


    .slider_home_bg{
        height: 510px !important;
        margin-top: 70px;
    }

    .v4uimobile .sliderFirstPara {
        top: 68% !important;
    }

    .new-mobile-header-all{
        position: sticky;
        top: -4px !important;
    }

    .flx_breadcrum {
        width: 100%;
        font-size: 12px;
    }


    .topfilterflexbar {
        padding: 10px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .flx_sortby {
        width: 100%;
    }

    .pageTitleH1 H1 {
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 600;
    }
    
    .new-main-menu{
        width: 100%;
        display: block;
        padding-left: 20px;
    }
    .new-mobile-header{
        position: sticky;
    }

    .navbar {
        padding: 0px;
        position: sticky;
        z-index: 4;
        top: 70px;
    }

    .v4uimobile .sliderFirstPara {
        top: 80%;
        display: inline-block;
        width: 100%;
        position: relative;
        padding: 30px;
    }


    .tilesarea_slider {
        padding-left: 15% !important;
        padding-right: 15% !important;
    }


    .fleximgleft {
        width: 220px;
        border-radius: 10px;
        height: auto !important;
    }

    .exclusivepartner {
        width: 50% !important;
        height: 240px;
    }
    
    .sliderFirstPara div{
        display: inline-block;
    }
    
    .slider_home{
        margin-top: 75px;
    }
    
    .howtouse {
        padding-top: 60px;
        background-repeat: no-repeat;
        background-position: 120px 85px;
        background-size: 550px;
    }
    
    .blogitem{
        display: inline-block;
        margin: 15px;
        width: 250px !important;
    }
    
    .sliderposts {
        width: 40% !important;
        margin-left: AUTO;
        margin-right: auto;
    }
    .blogimg{
        width: 250px;
        height: 140px;
    }

    .blogimg img {
        width: 100%;
        height: auto;
    }

    .adimg img {
       height: auto;
    }  
    
    .header-access-icons{
        gap: 25px;
    }
    .pull-right {
        float: right;
        /* display: flex; */
        right: 35px;
    }

}

/* landscape ipad air*/

@media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)  
{ 

    .slider_home {
        background-size: cover;
        background-position: center;
    }

    .slider_home{
        margin-top: 0px;
    }

    .tilesarea_sliderv4 {
        padding: 370px 7% 45px;
    }

    .fleximgleft {
        width: 185px;
        border-radius: 10px;
        height: auto;
    }


    .blogitem {
        width: 240px !important;
        padding: 10px;
    }

    .articleblogs {
        position: relative;
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 35px;
    }
    .blogimg_sm img{
        width: 250px;
        height: auto;
    }

    .blogimg img {
        height: 140px !important;
        width: 250px !important;
    }

    .viewmore {
        padding-left: 0;
        display: inline-block;
        margin: 25px;
    }

    .adimg img {
        width: 980px !important;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .adblock{
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media only screen and (max-width: 1114px)   
{ 

    .tilesarea_sliderv4 {
        padding: 356px 10% 45px;
    }

    .sliderFirstPara {
        background-color: #1f3087c9;
        color: #fff;
        /* display: flex; */
        font-family: Montserrat;
        font-size: 21px;
        font-weight: 500;
        justify-content: center;
        padding-bottom: 25px;
        padding-top: 25px;
        text-align: center;
        text-shadow: 0 0 1.15em #000;
        display: inline-block;
        width: 100%;
    }
}

@media only screen and (max-width: 1114px)   
{ 

    .tilesarea_sliderv4 {
        padding: 350px 2% 45px;
    }

    .exclusivepartner {
        height: 240px;
        width: 90% !important;
    }
    
    .blogimg img {
        height: auto !important; 
        width: 100%!important;
    }

    .blogitem{
        width: 100%!important;
    }
}



@media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)  
{ 
    .tilesarea {
        padding: 40px 5% 20px;
    }
    .sliderblock {
        padding-top: 10px;
        padding-bottom: 30px;
        position: relative;
        margin-left: 9%;
        margin-right: 9%;
    }

    .search_section {
        margin-left: 5%;
        margin-right: 5%;
    }

    .girlchildbg {
        background-size: 430px;
        background-position: 120px -25px;
        background-repeat: no-repeat;
        padding-bottom: 50px;
    }
   
}

@media only screen and (min-width: 1440px){
    .tilesarea_sliderv4 {
        padding: 369px 15% 45px;
    }

    .sliderblock {
        padding-top: 10px;
        padding-bottom: 30px;
        position: relative;
        margin-left: 15%;
        margin-right: 15%;
    }

    .tilesarea {
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 40px;
        padding-top: 40px;
    }

    .blogimg img {
        width: 270px;
        height: 100%;
    }

    .articleblogs {
        position: relative;
        margin-left: 10%;
        margin-right: 10%;
        padding-top: 35px;
    }

    .search_section {
        margin-left: 10%;
        margin-right: 10%;
    }

    .slider_home{
        background-size: cover;
        background-position: center;
    }
}

@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1024px)  
{ /* STYLES GO HERE */

    .tilesarea {
        padding: 40px 5% 20px;
    }

    .search_section{
        margin-left: 10%;
        margin-right: 10%;
    }

    .sliderblock {
        padding-top: 10px;
        padding-bottom: 30px;
        position: relative;
        margin-left: 9%;
        margin-right: 9%;
    }

    .fleximgleft{
        height: auto;
    }

    .teachersection_flex {
   
        height: auto;
    }
    
    .partnersection_flex{
        height: auto;
    }



}  

.textcenter{
    text-align: center;
}


/* all mobile devices */
 @media screen and (max-width: 767px) {


    .btnfloatright{
        text-align:center;
    }
    .noshowonmobile{
        display: none;
    }

    .compare-table-encl .table-responsive{
        margin-left: 0px;
        width: 100%;
    }

    .mobilenotes{
        display: inline-block;
        padding: 5px;
    }

    .breadcrumbsv2 {
        display: flex;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .compareclear{
        display: none;
    }
    
    .bannerimage {
        height: auto;
        width: 85%;
        /* padding: 10px; */
        text-align: center;
    }

    .adimg {
        height: 430px;
    }
    
    .adimg img{
        height: auto !important;
        width: auto !important;
    }

    .sliderposts{
        width: 100%;
    }

    .blogitem{
        width: 100%;
    }
    
    .bloglink{
        display: flex;
        padding-top: 10px;
    }

    .twosectionflex{
        flex-direction: column;
    }

    .clicktojoin{
        text-align: center;
        position: relative;
    }
    
    .partnersection_flex{
        width: 100%;
        flex-direction: column;
        height: auto;
    }

    .teachersection_flex{
        width: 100%;
        flex-direction: column; 
        height: auto; 
    }

    .sliderFirstPara h1 {
        font-size: 19px;

    }

    .tile-stamp-img {
        left: 4px;
        position: absolute;
        top: 8px;
    }
    
    .flexiblecolumn{
        display: flex;
        align-items: center;
        flex-direction: row;
        gap:4px;
    }
    .alignicons{
        padding-left:0px;
    }
    .dv-star-rating-star i {
        font-style: normal;
        font-size: 16px;
    }
    .bewiseratingsv1{
        font-size: 12px;
        line-height: 25px;
    }
    .orginfo{
        width: 200px;
    }
    .dv-star-rating-star{
        margin-bottom: 0px;
    }

    .displayflex{
        padding: 20px;
    }
    .org_name {
        font-size: 18px !important;
    }
    .actionbutv2{
        font-size: 8px;
    }

    .displayflexcolumn{
        display: flex;
        flex-direction: column;
    }

    .flex-row-joinus {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .orgfollows {
        display: flex;
        flex-direction: row;
        gap: 15px;
        padding-left: 30px;
        padding-top: 15px;
    }
    
    .third-features-content h3{
        word-break: break-word;
    }   

.wcicon{

    padding-top: 10px;
    padding-bottom: 6px;
} 

.services-icon{
    margin-bottom: 8px;
}
.service-overlap .single-services-wrap {
    background: hsla(0,0%,100%,.9);
    border: 2px solid #ededed;
    box-shadow: 0 0 10px rgba(0,0,0,.07);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,.07);
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.07);
    height: calc(100% - 40px);
}

.features-area h2 {
    border-bottom: 3px solid #ff5994;
    color: #212529;
    display: inline;
    font-size: 17px !important;
    font-weight: 600;
    margin-bottom: 0;
    text-align: left;
    width: auto;
}

.mobileRowBG{
    background: url('bgbannerformobile.webp') !important;
    height: auto;
}


.mt20onlydesktop{
    margin-top: 10px !important;
}

.preloadstep{
    background: hsla(0,0%,100%,.9);
    border: 2px solid #ededed;
    margin:0px !important;
    padding:10px;
    margin-bottom:10px !important;
}


.acc {
    bottom: 80px !important;
}


.acd{
    display: none !important;
    opacity: 1 !important;
}

.artcileslist {
    max-height: none !important;
    overflow: auto;
    overflow-x: hidden;
}

.ad380 {
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.orgphoto{

    padding: 5px;
    margin: 10px;
 
}

.floating-menu {
    background: #82b6ff;
    padding: 5px;
    width: 215px;
    z-index: 100;
    position: fixed;
    bottom: 40px;
    right: -1px;
}

.floating-menu a:hover{
    font-weight: 600;
}

.floating-menu-btn {
    background: #82b6ff;
    padding: 5px;
    width: 32px;
    z-index: 100;
    position: fixed;
    bottom: 215px;
    right: 0px;
    color: #fff;
    text-align: center;
}

.floating-menu-btn-listing {
    display: block !important;
}

    .ki_title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    color: #2d1fc9;
    white-space: nowrap;
    }

    .desktoponly{
        display: none !important;
    }
     .mobileonly{
        display: block !important;
    }
    .ver_detail_block{
        padding: 20px;
        margin: 0px;
    }
    .ver_action_block{
        background-color: unset !important;
    }
    .org_name {
        font-size: 15px;
        font-weight: 600;
        line-height: 40px;
        padding: 8px;
        text-align: center;
    }

    .collg3custom{
        width: 100%;
        max-width: 100% !important;
    }
    .bcitem{
        font-size: 8px;
    }
    .new-breadcrumbs-v2 span, .new-breadcrumbs-v2 a{
        font-size: 11px !important;
    }
    .org_address{
        padding-left: 15px;
        padding-right: 15px;
    }
    .orgphoto {
        width: 85%;
    }

    .ver_action_block{
        padding-left: 20px;
        padding-right: 20px;
    }

    .ratingrow {
        margin-top: 15px;
        padding-left: 5px;
        padding-right: 15px;
    }

.actionbtn .icondiv {
    background-color: #fff;
    border-radius: 19px;
    width: 32px;
    padding: 6px;
    display: inline-block;
    margin-bottom: 20px;
}

.actionbtn div img {
    width: 16px;
    height: 16px;
}
.actionbtn .textdiv {
    font-weight: 600;
    font-size: 9px;
    text-align: center;
    width: 100%;
}
.bewiseratings {
    float: left;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
}

.googleratings {
    float: left;
    text-transform: uppercase;
    font-size: 10px;
    padding-left: 10px;
    font-weight: 600;
}

.bewiseratings span {
    background-color: #ff5994;
    display: inline-block;
    margin: auto;
    text-align: right;
    float: right;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 0px;
    margin-left: 6px;
}

.googleratings span {
    background-color: #ff5994;
    display: inline-block;
    margin: auto;
    text-align: right;
    float: right;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 0px;
    margin-left: 6px;
}
.follownadlikerow {
    padding-top: 10px;
}

.follownadlikerow {
    padding-top: 10px;
    text-align: center;
    padding-left: 5px;
}
.followerscount {
    float: left;
    text-transform: uppercase;
    padding-right: 15px;
    font-size: 9px;
}

.likescount {
    float: left;
    padding-right: 15px;
    text-transform: uppercase;
    font-size: 9px;
}
    .org_address {
        font-size: 12px;
        font-weight: 600;
    }
    .desktop{
        display: none;
    }
    .mobile{
        display: flex !important;
    }
    .rowBg {
        background: url("/src/assets/images/bewisemainbg.svg");
        height: 100%;
        background-size: cover;
    }

    .mainsearcharea{
      display: none !important;  
    }

    .topmainsearch{
        display: none !important;
    }

    .choices-single-defaul{
        width: 100%;
    }

    .bigslogan {
        padding-top: 45px !important;
    }
    .sitedescblock{
        display: none;
    }
    .cityselect select{
        width: 100%;
    }

    .orgphoto {
        width: 100% !important;
    }
    .infoBlock{
        display: none;
    }

    .onDemandInfo h3 {
    text-decoration: none !important; 
    font-size: 14px;
    font-weight: 600;
    }

    .key-po-rating {
    background-color: #ff5994;
    display: inline-block;
    margin: auto;
    text-align: right;
    float: right;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    padding-left: 4px;
    padding-right: 3px;
    margin-top: 4px;
    margin-left: 10px;
    }

    .mobileblock{
        display: block !important;
    }

    .org_address {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        margin-left: 0px;
    }

    .googleratings {
        float: left;
        text-transform: uppercase;
        font-size: 10px;
        padding-left: 0px;
        font-weight: 600;
        margin-top: 7px;
    }

    .likescount {
        float: left;
        padding-left: 0px;
        text-transform: uppercase;
        margin-top: 7px;
    }

    .bewiseratings_section{
        display: flex;
        flex-direction: column;
    }

    .bcitem_div{
        background-color: #ffffff;
    }

    .similaritems {
        /* margin-top: 25px; */
        margin-left: 15px;
        margin-right: 15px;
        /* margin-bottom: 25px; */
        padding-left: 10px;
        padding-right: 10px;
    }


    .ver_detail_block {
        padding: 20px;
        margin: 0px;
        padding-bottom: 5px;
    }    
    .org_name {
        font-size: 15px;
        font-weight: 600;
        line-height: 17px;
        /* padding: 8px; */
        text-align: left;
        padding-left: 15px;
        color:#ffffff;
    }

    .bcitem_div {
        width: 4px;
        height: 4px;
        color: #000;
        background: #000;
        border-radius: 50%;
        
        display: inline-block;
        margin: 3px 5px;
    }

    .bcitem_div i {
        padding: 5px;
        font-size: 10px;
    }

    .breadcrumbsv3mob {
        padding: 4px;
        padding-top: 4px;
        height: 35px;
        margin-left: 0px;
        margin-top: 5px;
    }

    .amenitiesinfo{
        text-transform: uppercase;
        font-size: 11px;
        padding-top: 10px;
        padding-bottom: 10px;
    }


    .bs_social{
        /* text-align: center; */
        padding-bottom: 20px;
    }

    .bs_social h4 {
        font-size: 12px;
        color: grey;
        text-decoration: underline;
        text-align: center !important;
        padding-left: 0px !important;
    }

    .orgphoto {
        width: 200px !important;
    }

    .orginfo {
        width: 100%;
        text-align: center;
    }
    
    .bannersliders{
        padding: 10px;
    }

    .logobox {
        display: block;
        vertical-align: middle;
        height: auto;
        width: 100%;
        text-align: center !important;
        padding: 0px !important;
    }

    .part50percentage {
        width: 100% !important;
        margin-top: 12px !important;
    }

    .leftimage {
        float: left !important;
        padding: 10px !important;
        width: 85px !important;
    }

    .leftimage img {
        width: 80px !important;
    }

    .righttext {
        font-size: 11px !important;
        padding-left: 70px !important;
        padding-top: 10px !important;
    }


    .bewise-signin-block {
        padding-right: 0;
        margin-left: 2px !important;
    }

    .v2uimobile .slider_home{
        background-size: auto;
        padding-bottom: 50px;
    }

    .sliderText{
        padding-top: 45px;
        padding-left:15px;
        padding-right: 15px;
    }

    .sliderPara{
        padding-left:15px;
        padding-right: 15px;
        padding-bottom: 40px;
    }

    .v2uimobile .s003 form .inner-form .input-field.third-wrap {
        width: 70px !important;
    }

    .s003 form .inner-form .input-field input {
        height: 100%;
        background: 0 0;
        border: 0;
        display: block;
        width: 100%;
        padding: 5px 20px;
        font-size: 16px;
        color: #555;
    }

    .fleximgleft{
        width: 100%;
    }
    .v2uimobile .cityselect select{
        padding: 10px;
    }

    .tilesarea {
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20px;
        padding-top: 15px;
    }
    .flex-item-howtouse{
        width: 100% !important;
    }
    .tilesarea_slider {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 100px;
        padding-top: 40px;
    }

    .grid-container {
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .paddingtop16 {
        padding-top: 25px;
    }

    .card-back {
        font-size: 12px;
        text-align: left;
    }
    .scrollx ul{
        /* overflow: scroll; */
        display: -webkit-inline-box;
        width: auto;
        overflow-x: scroll;
        align-items: flex-start;
        display: flex;
        /* flex-wrap: nowrap; */
        list-style: none;
        margin: 1rem 0;
        overflow-x: scroll;
        padding: 0 0 0 0px;
        align-items: flex-start;
    }
    
    .card-vertical{
        height:185px;
        width:100%;
        -moz-transform-style:preserve-3d;
        -webkit-transform-style:preserve-3d;
        transform-style:preserve-3d;
        transition:all .4s ease;
    }

    .flex-container-1{
        display: flex;
        flex-direction: column;
        gap:20px;
    }

    .flex-item-howtouse-arrow img {
        width: 30px;
        height: auto;
        transform: rotate(90deg);
    }

    .grid-container-1 {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .benefits .griditem_icon img{
        width: 100%;
    }

    .grid-item-benefits {
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px lightgray;
        
    }
 
    .exclusivepartner {
        width: 260px !important;
        height: 230px;
        padding: 20px;
        /*box-shadow: 0px 2px 5px 0px lightgray;*/
        border: 1px solid lightgray;
        border-radius: 5px;
        margin:15px; 
        display: inline-block;
    }

    .howtouse{
        background-repeat: no-repeat;
        background-position: 0px 90px;
        background-size: contain;
    }

    .newtitle_home {
        font-family: 'Montserrat';
        text-align: center;
        font-size: 25px;
        color: #007BFF;
        font-weight: 600;
        padding-bottom: 10px;
        text-shadow: 4px 1px 5px #fff;
    }

    .sliderblock {
        padding-top: 10px;
        padding-bottom: 30px;
        position: relative;
        margin-left: 9%;
        margin-right: 9%;
    }

    .tilesarea_slider {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 20px;
        padding-top: 40px;
    }

    .flex-item-howtouse img {
        /* height: 50px; */
        width: 60px;
        height: auto;
    }
    .flex-item-howtouse .griditem_icon {
        min-height: 85px;
    }

    .search_section {
        margin-left: 5%;
        margin-right: 5%;
    }

    .flexcolumnone{
        display: flex;
        flex-direction: column;
        gap:20px;
    }

    .sliderposts .blogitem {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .v2uimobile .articleblogs {
        padding: 0px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .v2uimobile .blogimg img {
        border-radius: 10px;
        width: 100%;
    }

    .before-wrap img {
        text-align: center;
        padding: 10px;
        width: 52px;
        height: 40px;
    }

    .header-search{
        width: 140px;
    }

    .blogimg{
        text-align: center;
    }

    .excl_link{
        display: inline-block;
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }

    .blogdetail {
        padding-top: 1px;
        padding-bottom: 16px;
        padding-left: 5px;
    }

    .blogdetail {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .plainlink {
        /* border: 1px solid #007bff; */
        /* padding: 10px; */
        border-radius: 5px;
        font-size: 12px;
        color: #007bff;
    }

    .floatstar {
        position: absolute;
        left: 0;
        top: -12px;
    }
    .ver_action_block {
        padding-left: 0px;
        padding-right: 10px;
    }

    .no-padding {
        padding: 0px !important;
        margin-bottom: 10px;
    }

    .articleblogs {
        margin-left: 5%;
        margin-right: 5%;
        position: relative;
    }

    .v4uimobile .sliderFirstPara {
        display: block !important;
    }

    .homebanner_bewise{
        margin-top:70px;
    }
    .homebanner_bewise img{
        width: 100%;
    }
    .exclprtnblock .bcitem span {
        color: #000;
        font-size: 7px !important;
        text-transform: capitalize;
    } 
}

 @media only screen and (min-width: 768px) and (max-width: 1024px)  {

    .submenu-list{
        left: 5px;
        padding-left: 0px;
        top: 5px;
        bottom:5px;
    }

    .slider_home_bg {
         height: 620px;
    }

    .fortablet{
        display: none;
    }

    .articleblogs {
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 35px;
        position: relative;
    }
    
    .blogimg img {
        height: 140px;
        width: 250px;
    }

    .desktoponly{
        display: none !important;
    }
    
    .mobileonly{
        display:block !important;
    }

    .adimg {
        height: 430px;
    }
    
    .sliderposts{
        width: 100%;
    }

    .blogitem{
        width: 100%;
    }
    
    .bloglink{
        display: flex;
        padding-top: 10px;
    }

    .twosectionflex{
        flex-direction: column;
    }

    .clicktojoin{
        text-align: center;
    }
    
    .partnersection_flex{
        width: 100%;
        flex-direction: column;
    }

    .teachersection_flex{
        width: 100%;
        flex-direction: column;  
    }

    .tile-stamp-img {
        left: 4px;
        position: absolute;
        top: 8px;
    }
    
    .flexiblecolumn{
        display: flex;
        align-items: center;
        flex-direction: row;
        gap:4px;
    }
    .alignicons{
        padding-left:0px;
    }
    .dv-star-rating-star i {
        font-style: normal;
        font-size: 16px;
    }
    .bewiseratingsv1{
        font-size: 12px;
        line-height: 25px;
    }
    .orginfo{
        width: 200px;
    }
    .dv-star-rating-star{
        margin-bottom: 0px;
    }

    .displayflex{
        padding: 20px;
    }
    .org_name {
        font-size: 18px !important;
    }
    .actionbutv2{
        font-size: 10px;
    }

    .displayflexcolumn{
        display: flex;
        flex-direction: column;
    }

    .flex-row-joinus {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .orgfollows {
        display: flex;
        flex-direction: row;
        gap: 15px;
        padding-left: 30px;
        padding-top: 15px;
    }
    
    .third-features-content h3{
        word-break: break-word;
    }   

.wcicon{

    padding-top: 10px;
    padding-bottom: 6px;
} 

.services-icon{
    margin-bottom: 8px;
}
.service-overlap .single-services-wrap {
    background: hsla(0,0%,100%,.9);
    border: 2px solid #ededed;
    box-shadow: 0 0 10px rgba(0,0,0,.07);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,.07);
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.07);
    height: calc(100% - 40px);
}

.features-area h2 {
    border-bottom: 3px solid #ff5994;
    color: #212529;
    display: inline;
    font-size: 17px !important;
    font-weight: 600;
    margin-bottom: 0;
    text-align: left;
    width: auto;
}

.mobileRowBG{
    background: url('bgbannerformobile.webp') !important;
    height: auto;
}


.mt20onlydesktop{
    margin-top: 10px !important;
}

.preloadstep{
    background: hsla(0,0%,100%,.9);
    border: 2px solid #ededed;
    margin:0px !important;
    padding:10px;
    margin-bottom:10px !important;
}


.acc {
    bottom: 80px !important;
}


.acd{
    display: none !important;
    opacity: 1 !important;
}

.artcileslist {
    max-height: none !important;
    overflow: auto;
    overflow-x: hidden;
}

.ad380 {
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.orgphoto{

    padding: 5px;
    margin: 10px;
 
}

.floating-menu {
    background: #82b6ff;
    padding: 5px;
    width: 215px;
    z-index: 100;
    position: fixed;
    bottom: 40px;
    right: -1px;
}

.floating-menu a:hover{
    font-weight: 600;
}

.floating-menu-btn {
    background: #82b6ff;
    padding: 5px;
    width: 32px;
    z-index: 100;
    position: fixed;
    bottom: 215px;
    right: 0px;
    color: #fff;
    text-align: center;
}

.floating-menu-btn-listing {
    display: block !important;
}

    .ki_title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    color: #2d1fc9;
    white-space: nowrap;
    }

    .desktoponly{
        display: none !important;
    }
     .mobileonly{
        display: block !important;
    }
    .ver_detail_block{
        padding: 20px;
        margin: 0px;
    }
    .ver_action_block{
        background-color: unset !important;
    }
    .org_name {
        font-size: 15px;
        font-weight: 600;
        line-height: 40px;
        padding: 8px;
        text-align: center;
    }

    .collg3custom{
        width: 100%;
        max-width: 100% !important;
    }
    .bcitem{
        font-size: 8px;
    }
    .new-breadcrumbs-v2 span, .new-breadcrumbs-v2 a{
        font-size: 11px !important;
    }
    .org_address{
        padding-left: 15px;
        padding-right: 15px;
    }
    .orgphoto {
        width: 85%;
    }

    .ver_action_block{
        padding-left: 20px;
        padding-right: 20px;
    }

    .ratingrow {
        margin-top: 15px;
        padding-left: 5px;
        padding-right: 15px;
    }

.actionbtn .icondiv {
    background-color: #fff;
    border-radius: 19px;
    width: 32px;
    padding: 6px;
    display: inline-block;
    margin-bottom: 20px;
}

.actionbtn div img {
    width: 16px;
    height: 16px;
}
.actionbtn .textdiv {
    font-weight: 600;
    font-size: 9px;
    text-align: center;
    width: 100%;
}
.bewiseratings {
    float: left;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
}

.googleratings {
    float: left;
    text-transform: uppercase;
    font-size: 10px;
    padding-left: 10px;
    font-weight: 600;
}

.bewiseratings span {
    background-color: #ff5994;
    display: inline-block;
    margin: auto;
    text-align: right;
    float: right;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 0px;
    margin-left: 6px;
}

.googleratings span {
    background-color: #ff5994;
    display: inline-block;
    margin: auto;
    text-align: right;
    float: right;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 0px;
    margin-left: 6px;
}
.follownadlikerow {
    padding-top: 10px;
}

.follownadlikerow {
    padding-top: 10px;
    text-align: center;
    padding-left: 5px;
}
.followerscount {
    float: left;
    text-transform: uppercase;
    padding-right: 15px;
    font-size: 9px;
}

.likescount {
    float: left;
    padding-right: 15px;
    text-transform: uppercase;
    font-size: 9px;
}
    .org_address {
        font-size: 12px;
        font-weight: 600;
    }
    .desktop{
        display: none;
    }
    .mobile{
        display: flex !important;
    }
    .rowBg {
        background: url("/src/assets/images/bewisemainbg.svg");
        height: 100%;
        background-size: cover;
    }

    .mainsearcharea{
      display: none !important;  
    }

    .topmainsearch{
        display: none !important;
    }

    .choices-single-defaul{
        width: 100%;
    }

    .bigslogan {
        padding-top: 45px !important;
    }
    .sitedescblock{
        display: none;
    }
    .cityselect select{
        width: 100%;
    }

    .orgphoto {
        width: 100% !important;
    }
    .infoBlock{
        display: none;
    }

    .onDemandInfo h3 {
    text-decoration: none !important; 
    font-size: 14px;
    font-weight: 600;
    }

    .key-po-rating {
    background-color: #ff5994;
    display: inline-block;
    margin: auto;
    text-align: right;
    float: right;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    padding-left: 4px;
    padding-right: 3px;
    margin-top: 4px;
    margin-left: 10px;
    }

    .mobileblock{
        display: block !important;
    }

    .org_address {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        margin-left: 0px;
    }

    .googleratings {
        float: left;
        text-transform: uppercase;
        font-size: 10px;
        padding-left: 0px;
        font-weight: 600;
        margin-top: 7px;
    }

    .likescount {
        float: left;
        padding-left: 0px;
        text-transform: uppercase;
        margin-top: 7px;
    }

    .bewiseratings_section{
        display: flex;
        flex-direction: column;
    }

    .bcitem_div{
        background-color: #ffffff;
    }

    .similaritems {
        /* margin-top: 25px; */
        margin-left: 15px;
        margin-right: 15px;
        /* margin-bottom: 25px; */
        padding-left: 10px;
        padding-right: 10px;
    }


    .ver_detail_block {
        padding: 20px;
        margin: 0px;
        padding-bottom: 5px;
    }    
    .org_name {
        font-size: 15px;
        font-weight: 600;
        line-height: 17px;
        /* padding: 8px; */
        text-align: left;
        padding-left: 15px;
        color:#ffffff;
    }

    .bcitem_div {
        content: dot;
        content: "\A";
        width: 4px;
        height: 4px;
        color: #000;
        background: #000;
        border-radius: 50%;
        float: left;
        display: inline-block;
        margin: 3px 5px;
    }

    .breadcrumbsv3mob {
        padding: 4px;
        padding-top: 4px;
        height: 35px;
        margin-left: 0px;
        margin-top: 5px;
    }

    .amenitiesinfo{
        text-transform: uppercase;
        font-size: 11px;
        padding-top: 10px;
        padding-bottom: 10px;
    }


    .bs_social{
        /* text-align: center; */
        padding-bottom: 20px;
    }

    .bs_social h4 {
        font-size: 12px;
        color: grey;
        text-decoration: underline;
        text-align: center !important;
        padding-left: 0px !important;
    }

    .orgphoto {
        width: 200px !important;
    }

    .orginfo {
        width: 100%;
        text-align: center;
    }
    
    .bannersliders{
        padding: 10px;
    }

    .logobox {
        display: block;
        vertical-align: middle;
        height: auto;
        width: 100%;
        text-align: center !important;
        padding: 0px !important;
    }

    .part50percentage {
        width: 100% !important;
        margin-top: 12px !important;
    }

    .leftimage {
        float: left !important;
        padding: 10px !important;
        width: 85px !important;
    }

    .leftimage img {
        width: 80px !important;
    }

    .righttext {
        font-size: 11px !important;
        padding-left: 70px !important;
        padding-top: 10px !important;
    }


    .bewise-signin-block {
        padding-right: 0;
        margin-left: 2px !important;
    }

    .v2uimobile .slider_home{
        background-size: auto;
        padding-bottom: 100px;
    }

    .sliderText{
        padding-top: 45px;
    }

    .v2uimobile .s003 form .inner-form .input-field.third-wrap {
        width: 70px !important;
    }

    .v2uimobile .cityselect select{
        padding: 10px;
    }

    .tilesarea_slider {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 100px;
        padding-top: 40px;
    }

    .grid-container {
        display: grid;
        gap: 50px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .flex-container-1{
        display: flex;
        flex-direction: column;
        gap:20px;
    }

    .flex-item-howtouse-arrow img {
        width: 30px;
        height: auto;
        transform: rotate(90deg);
    }

    .grid-container-1 {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .benefits .griditem_icon img{
        width: 100%;
    }

    .grid-item-benefits {
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px lightgray;
    }
 
    .exclusivepartner {
        padding: 20px;
        box-shadow: 0px 2px 5px 0px lightgray;
        border-radius: 5px;
        margin:0px; 
        display: inline-block;
    }

    .newtitle_home {
        font-family: 'Montserrat';
        text-align: center;
        font-size: 25px;
        color: #007BFF;
        font-weight: 600;
        padding-bottom: 10px;
    }

    .sliderblock {
        padding-top: 10px;
        padding-bottom: 30px;
        position: relative;
        margin-left: 15%;
        margin-right: 15%;
    }

    .tilesarea_slider {
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 20px;
        padding-top: 40px;
    }

    .flex-item-howtouse img {
        /* height: 50px; */
        width: 60px;
        height: auto;
    }
    .flex-item-howtouse .griditem_icon {
        min-height: 85px;
    }

    .search_section {
        margin-left: 5%;
        margin-right: 5%;
    }

    .flexcolumnone{
        display: flex;
        flex-direction: column;
        gap:20px;
    }

    .sliderposts .blogitem {
        display: flex;
        flex-direction: row;
        gap:10px;
    }

    .v2uimobile .articleblogs {
        padding: 0px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .v2uimobile .blogimg img {
        border-radius: 10px;
        width: 200px;
    }

    .excl_link{
        display: inline-block;
        text-align: center;
        width: 100%;
        padding-top: 30px;
    }

    .blogdetail {
        padding-top: 1px;
        padding-bottom: 16px;
        padding-left: 5px;
    }

    .plainlink {
        /* border: 1px solid #007bff; */
        /* padding: 10px; */
        border-radius: 5px;
        font-size: 12px;
        color: #007bff;
    }

    .floatstar {
        position: absolute;
        left: 0;
        top: -12px;
    }
    .ver_action_block {
        padding-left: 10px;
        padding-right: 10px;
    }

    .no-padding {
        padding: 0px !important;
        margin-bottom: 10px;
    }

    .articleblogs {
        margin-left: 5%;
        margin-right: 5%;
        position: relative;
    }

    .flex-item-howtouse {
        border-radius: 5px;
        box-shadow: 0 0 0 1px #d3d3d3;
        padding: 25px;
        width: 100%;
        height: 100%;
    }

    .partnersection_flex{
        flex-direction: row;
    }

    .teachersection_flex{
        flex-direction: row;
    }

    .adimg img {
        width: 400px !important;
    }

    .fleximgleft {
        width: 220px;
        border-radius: 10px;
        height: 70%;
    }

    .footer-bottom-menu{
        display: block !important;
    }
 }

 

 @media only screen and (max-width: 1280px)  
 { 

    .submenu-list{
        left: 0px;
        padding-left: 0px;
        top: 5px;
        bottom:5px;
    }

    /*
    .scrollmenu{
        display: none;
    }*/

    .container {
        max-width: 1120px;
    }

}
