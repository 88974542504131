/*body,html{*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;*/
/*}*/
.company-header {
  font-size: 26px;
  font-weight: bold;
}
.company-subhead a {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
  color: #212529;
}
.company-subhead a:hover {
  color: #ff5994 !important;
}
.footer-color {
  background-color: #fbd171;
}
@media (max-width: 767.98px) {
  .footer-color {
    margin-bottom: 57px;
  }
}
.blue-btn {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background: #82b6ff;
}
footer .app-icons {
  width: 60px !important;
  height: 60px !important;
}
.flex-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  justify-content: space-around;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}

a > img {
  margin: auto 12px;
  height: 22px;
  /* font-size: 22px; */
}
a:hover {
  transition: 0.2s linear;
  color: #000fff;
  cursor: pointer;
}
.flex-container > div {
  margin: 30px auto;
}
.flex-item > div {
  margin: 16px auto;
  color: white;
}

@media screen and (max-width: 600px) {
  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-item {
    width: 100%;
    text-align: center;
  }
}
/* @media only screen and (min-width: 600px){
    .flex-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      .flex-item{
          width: 100%;
          text-align: center;
    }
  } */
