@import "rsuite.css";
/* may 2nd */

.videoborder{
    
    text-align: center;
}
.videoborder img{
    box-shadow: 0px 0px 15px 6px #88888859;
}


.ad380{
    height: 100%;
}
.adspace {
    background-color: #fff;
    margin-bottom: 20px;
    margin-top: 20px;
}

.oneAd img{
    width: 100%;
}

.oneAd {
    background-color: #fff;
    height: 100%;
    text-align: center;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 12px;
}
#gallery_section .slick-slide img {
    display: inline;
    height: auto;
}

.adspace img{
    width: 100%;
    height: 100%;
    margin: auto;
}

  .toast-error{
    background-color:#BD362F !important;
    color: #fff !important;
 }
 .toast-success{
    background-color: green !important;
    color: #fff !important;
 }

 .insight-list-page .relatedLi p {
    margin-bottom: 0;
    text-align: left;
}

.ki_ans {
    padding-top: 5px;
    font-size: 14px;
    word-break: break-word;
}

.followerscount{
    cursor: pointer;
}

.likescount{
    cursor: pointer; 
}
.linkinwarning{
    color: #82b6ff;
}
.smallwarning{
    color: #000;
    font-size: 12px;
}
.toast-title{
    color: white !important;
}
.noneblock{
    font-size: 12px;
}

button.close-btn{
    width: 25px;
    height: 25px;
}

.close-btn img{
    width: 10px;
}

.csph4{
    font-size: 13px;
    text-align: center;
}

.close-btn {
    top: 10px!important;
    right: 10px!important;
}

.csp .btn-css{
    font-weight: 600;
    font-size: 12px;
}
/* nwew change june 1 */
.mainmenu-contain {
    padding: 5px;
    z-index: 9;
}

.navbar{
    padding: 0px;
    position: sticky;
    z-index: 4;
    top: 87px;
}

#floatinmid {
    background: #ff5994;
    color: white;
    /* display: block; */
    position: sticky;
    top: 120px;
    z-index: 9;
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
}

.ver_action_block_in_bar .actionbtn div img {
    width: 16px;
    height: 16px;
    margin-top: -3px;
}

.ver_action_block_in_bar .actionbtn .icondiv {
    background-color: #fff;
    border-radius: 20px;
    width: 30px;
    padding: 2px;
    display: inline-block;
    margin-bottom: 0px;
}

.ver_action_block_in_bar .icondivcss {
    background-color: #fff;
    border-radius: 30px;
    padding: 10px;
    width: 46px;
    float: left;
    margin-right: 15px;
    text-align: center;
    cursor: pointer;
}

.ver_action_block_in_bar{
    float: right;
}

.compare-table .compare-one td.title h4{
    font-size: 16px;
}

.table-card h4{
    font-size: 12px;
}

.table-card p {
    color: #24242c;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 20px; */
    line-height: 17px;
}

.m9b4f0v {
    font-size: 11px;
    font-weight: bold;
}

.add-school h4 {
    font-size: 11px;
    color: #fff;
    font-weight: 700;
}

.compare-table .card-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.compare-table .card-count .person-count {
    display: flex;
    align-items: center;
    margin-right: 9px;
    height: 10px;
    padding-top: 20px;
}

.collapse-body .collapse-subtitle h5 {
    padding-left: 30px;
    color: #24242c;
    font-size: 11px;
    font-weight: 500px;
}

.compare-table .card-count h5 {
    color: #24242c;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    margin-right: 15px;
    margin-top: 6px;
}

.table-card .gray-text {
    color: #63686e;
    font-size: 12px;
    line-height: 20px;
}
.blue-text{
    font-size: 11px;
}

.key-icon {
    padding: 10px 0px 10px 0px;
    margin-left: 40px;
    width: 154px;
    background-color: #128a82;
    border-radius: 4px;
}

.type-icon {
    display: flex;
    width: 44px;
    background-color: #f6d000;
    align-items: center;
    padding: 15px;
    float: left;
    height: 36px;
}

.type-icon img {
    width: 24px;
}

.compareclear{

    margin-left: 50%;
    width: 120px;
    font-size: 12px;
    padding: 4px;
 
}

.react-autosuggest__suggestions-container {
    background: #fff;
    box-shadow: -2px 7px 6px 0 rgba(0,0,0,.24);
    float: right;
    max-height: 130px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 999;
}

.blue-text{
    color: #000;
}

.col-md-41.pull-left {
    float: left;
    width: auto;
    padding-bottom: 0px;
    margin: 5px;
}

.distance{

    color: green;
    font-size: 14px;
    font-weight: bold;
    padding: 12px;

}
.tilebox{
    padding: 10px;
}

#onDemandInfo {
    display: inline !important;
}

.secound-landing>.container>.row {
    padding: 10px !important;
}

.features-area{
    padding-top: 20px;
}

.floating-menu{
    z-index: 10000;
}



.subjectinfo{
    font-size: 13px;
}

.exclprtrs-container {
    border: 1px solid rgba(0,0,0,.125);
    min-height: 280px;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: #82B6FF !important;
    border-color: #82B6FF !important;
}

.middleContExcl{
    margin-top: 15px;
    text-align: center;
}

.topLogo {
    /* min-height: 120px; */
    /* vertical-align: middle; */
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: center;
}

.topLogo img {
    width: 200px;
    vertical-align: middle;
    text-align: center;
}


.exclprtnrbox{
        width: 200px;
        margin: 20px;
}
.exclprtnrbox img{
    width: 100%;
}
.onDemandInfo{
    margin-top: 100px;
}
.exclprtnblock .review_head{
    font-size: 15px;
}
.exclprtnblock #review_section{
    padding: 35px;
}
.exclprtnblock .review_head {
    font-size: 15px;
    margin-left: 15px;
    margin-right: 19px;
}

.exclprtnblock #review_section {
    padding: 35px;
    border-top: 1px solid lightsteelblue;
    margin-top: 15px;
}
.bannersection{
    text-align: center;
}

.bannersection img{
    width: 85%;
}

.promocontent img{
    width: 300px;
    text-align: center;
}
.brochuresection label{
    font-weight: 700;
    display: block;
}
.downloadsection label{
    font-weight: 700;
    display: block;
}
.promosection label{
    font-weight: 700;
    display: block;  
}
.bs_website {
    padding-top: 12px;
    font-size: 14px;
}
.bs_website a{
    padding-left: 5px;
}

.downloadsection a{
    font-size: 11px;
    line-height: 37px;
}

.promosection img{
    width: 100%;
}
.bannersliders img{
    width: 100%;
}

.orgphoto {
    width: 60%;
}

.logobox{
    display: block;
    padding-bottom: 20px;
    vertical-align: middle;
    height: auto;
    width: 100%;
    text-align: left;
    padding-left: 14px;

}
.bs_social h4 {
    font-size: 12px;
    color: grey;
    text-decoration: underline;
    text-align: left;
    padding-left: 14px;
}

.ads fieldset {
    font-family: sans-serif;
    border: 3px solid #1F497D;
    background: #ddd;
    border-radius: 5px;
    padding: 6px;
}

.ads fieldset legend {
    background: #1F497D;
    color: #fff;
    font-size: 11px;
    border-radius: 5px;
    box-shadow: 0 0 0 5px #ddd;
}

.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color: white;
}

.containerTab {
  padding: 20px;
  color: #000;
  font-size: 14px;
}

.containerTab h2{
  font-size: 14px;
}
.column img{
    width: 100%;
}

.selectedoffer{

    display: block;
    background-color: #bfc5bb57;
 
}

.slidersection {
    padding-left: 30px;
    padding-right: 30px;
}

#slidersBnrsDesktop {
height: 250px;
}

.orgphoto {
    width: 80%;
}

.skippr {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;
}


.floating-menu a, .floating-menu h3 {
    color: #007bff;
    display: block;
    font-size: 10px;
    margin: 10px .5em;
    font-weight: 600;
    text-transform: uppercase;
}

.floating-menu a, .floating-menu h3 {
    color: #000 !important;
    display: block;
    font-size: 10px;
    margin: 10px .5em;
    font-weight: 600;
    text-transform: uppercase;
}


.floating-menu-btn {
    background: #fbd171;
    padding: 3px;
    width: 30px;
    z-index: 998;
    position: fixed;
    bottom: 50%;
    right: 10px;
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

.floating-menu {
    background: #fff;
    position: fixed;
    z-index: 1000;
    color: #fff;
    border: 1px solid #fbd171;
    /* border-right: none; */
    /* bottom: 52%; */
    padding: 3px;
    width: 180px;
    z-index: 10000;
    position: fixed;
    bottom: 48%;
    right: 0px;
    color: #000;
    text-align: left;
    font-weight: bold;
    font-size: 15px;
}


.highlighted{
   text-decoration: underline 3px solid #fbd171;
}
.floating-menu-close-btn {
    background: #fbd171;
    padding: 3px;
    width: 64px;
    z-index: 100;
    position: fixed;
    bottom: 83px;
    right: 0px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
}

/*search box*/
.search {
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}
.search {
    position: absolute;
    top: 41%;
    left: 43%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10000;
}
.search * {
  outline: none;
  box-sizing: border-box;
}
.search__wrapper {
  position: relative;
}
.search__field2 {
  width: 50px;
  height: 50px;
  color: transparent;
  font-size: 13px;
  padding: 0.35em 50px 0.35em 0;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
}
.search__field {
  width: 50px;
  height: 50px;
  color: transparent;
  font-size: 13px;
  padding: 0.35em 50px 0.35em 0;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.search__field:focus {
  border-bottom-color: #ccc;
  width: 50vw;
  color: #2b2b2b;
  cursor: default;
}

.search__icon2 {
  position: absolute;
  top: 0;
  right: 0;
  color: #ff5994;
  width: 50px;
  height: 50px;
  font-size: 1.35em;
  text-align: center;
  border-color: transparent;
  display: inline-block;
  background: transparent;
}
.search__icon {
  position: absolute;
  top: 0;
  right: 0;
  color: #ff5994;
  width: 50px;
  height: 50px;
  font-size: 1.35em;
  text-align: center;
  border-color: transparent;
  pointer-events: none;
  display: inline-block;
  transition: background-color 0.2s ease-in-out;
  background: transparent;
}
.search__field::-webkit-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: .1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field:-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: .1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field::-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: .1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.paddingbottom100{
    padding-bottom: 10px;
}
.search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: .1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field::-webkit-input-placeholder[style*=hidden] {
  color: #000;
  font-size: .65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field:-moz-placeholder[style*=hidden] {
  color: #000;
  font-size: .65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field::-moz-placeholder[style*=hidden] {
  color: #000;
  font-size: .65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field:-ms-input-placeholder[style*=hidden] {
  color: #000;
  font-size: .65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}


.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.suggestions {
    border: 1px solid #ab1373;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 150px;
    overflow-y: auto;
    /* padding-left: 0; */
    width: 343px;
    /* position: absolute; */
    /* left: 20px; */
    background-color: white;
    padding-left: 0px;
    border-radius: 6px;
}

.suggestion-active, .suggestions li:hover {
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    background-color: #ff5994;
    border: none;
}
.suggestions li {
    padding: 6px;
    font-size: 12px;
}

.suggestionbox {
    position: absolute;
    /* background-color: white; */
    padding: 4px;
    width: 265px;
    font-size: 13px;
    right: 0px;
    position: fixed;
    display: flex;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.newheader{
    padding-top: 15px;
    padding-bottom: 15px;
}


.search-box{
  position:relative;
  height:60px;
  width:0px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55 );
}


.search-box.active {
    width:115px;
}

.search-box.active input{
  opacity:1;
}

.search-box input{
  height:100%;
  width:100%;
  border:none;
  background:#fff;
  font-size:12px;
  border-radius: 50px;
  padding: 0 10px 0 10px;
  outline:none;
  opacity:0;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55 );
}

.search-box .search-btn{
  height:50px;
  width:25px;
  background: #fff;
  color:#ff5994;
  position: absolute;
  top:50%;
  left: 130px;
  transform: translateY(-50%);
  line-height:50px;
  text-align:center;
  border-radius:50%;
  font-size:20px;
  cursor:pointer;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55 );
  z-index:2;
}

.search-box.active .search-btn{
  height:50px;
  width:25px;
  color:#ff5994;
}

.search-box .cancel-btn {
    position: absolute;
    color: #ff5994;
    top: 45%;
    transform: translateY(-50%);
    color: #ff5994;
    font-size: 25px;
    cursor: pointer;
    height: 50px;
    width: 25px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    opacity: 0;
}

.searchin {
    padding-left: 0px;
}

.blinkingitem{
    display: inline-block;
}

/*

.blinkingitem .clicktext{
    animation: blinking  
                1.5s linear infinite; 
    position:relative;
}

*/
.header_bar{
    box-shadow: 0px 0px 5px 0px #000;
}

.clicktochange_left{
    display: inline;
    animation: blinking 3.0s linear infinite;
    font-size: 12px;
    font-weight: 600;
    padding-right: 10px;
}

.clicktochange_right {
    display: inline;
    animation: blinking 3.0s linear infinite;
    font-size: 12px;
    font-weight: 600;
}
@keyframes blinking { 
    0% { 
        opacity: 0; 
    } 

    50% { 
        opacity: 0.7; 
    } 

    100% { 
        opacity: 0; 
    } 
} 

.search-box.active .cancel-btn {
    right: -60px;
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55 );
    z-index: 2;
}
@media only screen and (max-width: 375px){
    .header-access-icons {
        padding-left: 0px !important;
    }
}

.returntop {
    background: #fbd171;
    padding: 3px;
    width: 64px;
    z-index: 100;
    position: fixed;
    bottom: 50px;
    right: 150px;
    color: #fff !important;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 2px;
    display: none;
}

.rsc-float-button {
    align-items: center;
    cursor: pointer;
    background: none !important;
    bottom: 17px !important;
    border-radius: 100%;
    box-shadow: none !important;
    display: flex;
    fill: none !important;
    height: auto;
    justify-content: center;
    position: fixed;
    right: 15px !important;
    transform: scale(1);
    transition: transform 0.3s ease;
    width: 109px;
    z-index: 999;
}
.rsc-float-button img{
    width: 109px !important;
    height: auto !important;
}

.rsc-header{
  background-color:#ffffff;
  /* background: rgb(255,255,255) !important; 
  background: linear-gradient(354deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 3%, rgba(69,123,189,1) 100%)!important; 
  */
  color: #000 !important; 
  height: 40px !important;
}
.rsc-header-title{
    font-weight: 600 !important;
}
.rsc-os-option-element{
    font-size: 12px !important;
    padding: 7px !important;
    font-weight: bold !important;
}
.rsc-ts-bubble{
    max-width: 80% !important;
    border-radius: 10px 10px 10px 10px !important;
    font-size: 12px !important;
}
.aboutinchatbox{
    color: #000;
    background-color: #fff;
    padding: 4px;
    font-size: 12px;
    margin: 4px;
    border-radius: 6px;
}
.rsc-ts-user .rsc-ts-bubble{
    background-color: #76867d38 !important;
    border-radius: 10px 10px 10px 10px !important;
}

.aboutinchatbox a{
    color: #FF5994;
     font-size: 15px;
}
.rsc-ts-bubble a{
    color: #007bff;
    font-size: 14px !important;
    text-decoration: none;
    background-color: transparent;
}
.rsc-ts-bubble a:hover{
    font-size: 14px !important;
    color: #007bff !important;
    text-decoration: underline;
    background-color: transparent;
    transition: unset !important;
}


.rsc-ts-bubble h3{
    font-size: 15px !important;
}

.rsc-container{
    box-shadow: 0px -1px 15px 2px lightgrey !important;
    height: 345px !important;
}
.rsc-submit-button{
 
    background-color: transparent;
    border: 0;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    fill: #4a4a4a;
    opacity: 1;
    outline: none;
    padding: 8px 14px 10px 10px !important;
    position: absolute;
    right: 0;
    top: 0;
 
}
.rsc-input {
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #eee;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 14px !important;
    opacity: 1;
    outline: none;
    padding: 10px 45px 10px 10px !important;
    width: 100%;
    -webkit-appearance: none;
}
/*
#return-to-top {
    position: fixed;
    bottom: 30px;
    right: 100px;
    background: rgba(73,122,255,.8);
    width: 50px;
    height: 50px;
    display: block;
    color: #fff;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}


#return-to-top .fa-arrow-up {
    color: #fff;
    padding: 12px;
    font-size: 18px;
}

#return-to-top {
    position: fixed;
    bottom: 17px !important;
    right: 130px !important;
    background-color: #457BBD !important;
    width: 42px !important;
    height: 42px !important;
    display: block;
    color: white;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
*/

/* floating menu */
.sticky-container {
/*background-color: #333;*/
    padding: 0px;
    margin: 0px;
    position: fixed;
    bottom: 40px;
    display: flex;
    z-index: 99;
    right: 0;
}
.sticky li {
list-style-type: none;
/* background-color: #82B6FF; */
color: #efefef;
height: 43px;
padding: 0px;
margin: 0px 0px 1px 0px;
-webkit-transition: all 0.25s ease-in-out;
-moz-transition: all 0.25s ease-in-out;
-o-transition: all 0.25s ease-in-out;
transition: all 0.25s ease-in-out;
cursor: pointer;
border-radius: 6px;
}
.sticky li:hover {
/* margin-left: -115px; */
/*-webkit-transform: translateX(-115px);
        -moz-transform: translateX(-115px);
        -o-transform: translateX(-115px);
        -ms-transform: translateX(-115px);
        transform:translateX(-115px);*/
        /*background-color: #8e44ad;*/
/* background-color: #82B6FF; */
}
.sticky li img {
float: left;
margin: 5px 5px;
margin-right: 10px;
width: 32px;
height: 32px;
border-radius: 6px;
/* background-color: #fff; */
}

.sticky li p {
    padding: 0px;
    margin: 0px;
    text-transform: capitalize;
    line-height: 43px;
    font-size: 12px;
    font-weight: 800;
}
.rsc-ts{
    margin-bottom: 5px !important;
}
.rsc-header-close-button {
    font-size: 18px;
    line-height: 35px;
    float: right;
    padding-right: 15px;

}

.rsc-header-title {
    font-size: 14px;
    padding-left: 12px;
    padding-top: 12px;
    float: left;
}

.rsc-ts-image{
    height: 45px !important;
    min-width: 40px !important;
    padding-bottom: 10px !important;
}

.rsc-ts-bot .rsc-ts-image{
    box-shadow: none !important;
} 

.rsc-ts-user .rsc-ts-bubble{
    margin: 0 0 3px 0 !important;
}

.rsc-ts .rsc-ts-user{
    margin-bottom: 10px !important;
}


.chatbotlink{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

.form-check-label {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: normal !important;
}

.finalstepform label {
    font-size: 12px;
    margin-bottom: 3px;
    margin-top: 3px;
    font-weight: 500;
}
.finalstepform .form-control {
    background: #fff;
    height: 28px;
    border-radius: 3px;
    font-size: 12px;
}

.leadform .card-header {
    font-size: 11px;
    padding: 7px;
}

.leadform .card-body  {
    font-size: 12px;
}

.leadform .card-footer {
    padding: 7px;
}

.leadform label {
    margin-bottom: 3px !important;
    margin-top: 5px !important;
    font-weight: 600;
}

#verifiedstatus{
    padding: 4px;
    font-weight: 600;
}

.finalstepform .form-control {
    background: #fff;
    height: 28px;
    border-radius: 3px;
}

.finalstepform .sendoptbutton{
    margin-top: 23px;
}

.verification{
    padding: 5px;
    font-size: 12px;
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}
.greentext{
    color: green;
}
.redtext{
    color: red;
}
.invalid{
    border:1px solid red;
    box-shadow: 0 0 10px #719ECE;
}
.valid{
    border:1px solid green;
    box-shadow: 0 0 10px green;
}

.box {
  width: 200px; height: 300px;
  position: relative;
  border: 1px solid #BBB;
  background: #EEE;
  margin: 20px;
  display: inline-block;
}
.bestseller:before {
  content: "";
  z-index: -1;
  overflow: hidden;
  transform: rotate(-135deg);
  width: 120px;
  display: block; 
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 34px;
  right: -16px;
  clip-path: polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px,  110px 30px);
  height: 20px;
  width: 120px;
}
.bestseller:after {
  content: "premium";
  z-index: 1;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  width: 120px;
  display: block; 
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 20px; right: -30px;
  clip-path: polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px,  110px 30px)
}

.dropdown-toggle.ccode  {
    width: 51px;
    margin-right: 5px;
    height: 27px;
    font-size: 12px;
}

#country-codes ul.dropdown-menu.show {
    background-color: #ffffff;
    color: #000;
}

#country-codes ul.dropdown-menu.show li a {
    color: #000;
    font-size: 12px;
    font-weight: normal;
}
#country-codes ul.dropdown-menu.show li a:hover {
    background-color: #fff !important;
    color: #000;
    font-weight: bold !important;
}
#country-codes{
    display: flex;
    margin-left: 0px;
}

.listing-lead{
    border-radius: 6px;
    margin-top: 15px;
}

.listing-lead-row {
    padding: 2px;
    font-size: 12px;
    margin: 10px;
}

.aftersubmitdisplay{
    padding: 10px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: bold;
    margin-top: 40px;
}
.fade-in-image { animation: fadeIn 5s; }
.aftersubmitdisplay p{
    margin-bottom: 0px;
}
.backoptions{
    padding: 10px;

}
.waiting{
    background-color: #0099CC;
    padding: 10px;
    border-radius: 6px;
    color: white;
    font-size: 13px;
    font-weight: bold;
}
.ver_action_block {
    z-index: 997 !important
}
.part50percentage {
    border: 1px solid #d3d3d3;
    float: left;
    height: auto;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
}

.leftimage img {
    width: 100px;
}
.righttext {
    /* float: left; */
    font-size: 11px;
    padding-left: 115px;
    padding-top: 20px;
}
.leftimage {
    float: left;
    width: 100px;
    padding: 10px;
}

.bannerimage{
    width: 100%;
}
.bannerimage {
    width: 100%;
    height: auto;
}
.containerrow {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
}
.borderwithcolor2{
    width: 100%;
    display: block;
    padding: 5px;
    background-color: #C6DCE4;
    border: 7px solid #DAEAF1; 
}
.borderwithcolor1{
    width: 100%;
    display: block;
    padding: 5px;
    background-color: #DAEAF1;
    border: 7px solid #C6DCE4;
}
.offer_right_text {
    float: left;
    width: 75%;
    padding-left: 10px;
    font-size: 13px;
    margin-top: 10px;
}
.offer_left_text {
    float: left;
    width: 75%;
    padding-left: 10px;
    font-size: 13px;
    margin-top: 10px;
}
.offer_right_img {
    width: 160px;
    float: left;
    text-align: right;
}
.offer_right_img img {
    width: 160px;
}
.offer_left_img {
    width: 160px;
    float: left;
    text-align: center;
}
.offer_left_img img{
    width: 160px;
}

.offer_row{
    margin-bottom: 15px;
}
.bcitem{
    cursor: pointer;
}
.nonclick{
    cursor: none !important;
}
.galleryimage{
    height: 250px !important;
}

.fieldset {
  position: relative;
  border: 1px solid #ddd;
  padding: 10px;
}

.fieldset h1 {
  position: absolute;
  top: 0;
  font-size: 12px;
  line-height: 1;
  margin: -8px 0 0; /* half of font-size */
  background: #fff;
  padding: 0 3px;
}
.section_adwords{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}
.adwordstags ul {
    list-style: none;
    padding-left: 0px;
}
.adwordstags ul li {
    background-color: lightgrey;
    padding: 2px;
    display: inline-block;
    font-size: 10px;
    font-style: italic;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.listing_nodata{
    font-size: 16px;
    color: red;
    padding: 10px;
}

.large-content{
    padding: 12px;
    font-size: 13px;
    margin-top: 13px;
}
.large-content h5{
    font-size: 15px;
}
.large-content h6{
    font-size: 14px;
}

.msg_black {
    color: #000;
    font-weight: 500;
}
.backoptions a{
    font-weight: 600;
    margin-bottom: 5px;
}
.homeexlprts{
    text-align: center;
    background-color: #F5F5F5;
    padding: 16px;
    width: 90% !important;
    margin-right: 15px;
    height: 95px;
}
.homeexlprts span{
    font-size: 13px;
    font-weight: normal;
}

.homeexlprts img{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.bottommargin20{
    margin-bottom: 20px;
}

.fileicon {
    height: 200px;
    width: 150px;
}

.large-content {
    /* height: 500px;
    overflow: hidden; */
}

.large-content-full {
    height: 100%;
    overflow: auto;
}

hr.style-eight {
    overflow: visible;
    padding: 0px;
    border: none;
    border-top: medium double lightgray;
    color: #333;
    text-align: center;
    margin-left: 10px;
    margin-right: 30px;
}
hr.style-eight:after {
    content: "read more";
    display: inline-block;
    position: relative;
    top: -1.3em;
    font-size: 13px;
    padding: 0px 19px;
    background: white;
}

#chatbotblock{
    z-index: 10000;
}

.sticky-container-chat {
    /* background-color: #333; */
    padding: 0px;
    margin: 0px;
    position: fixed;
    bottom: 0px;
    display: flex;
    z-index: 99;
    right: 0;
}

.tochat img {
    width: 27px !important;
    height: 27px !important;
}

#slideout {
    z-index: 999;
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 35px;
    padding: 0px;
    text-align: center;
    transition-duration: 0.3s;
}


#slideout_inner img {
    width: 250px;
    margin: 0px !important;
    height: 100%;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.mySlides {display: none;}

/* Fading animation */
.fade2 {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade2 {
  from {opacity: .4}
  to {opacity: 1}
}

@media screen and (max-width: 767px) {

    .btnreadmore{
        display: none;
    }

    .invisible-content{
        display: block !important;
    }

    #slideout_inner {
        bottom: 100px;
    }
     
    #slideout {
        bottom: 100px;
    }

    .ver_action_block {
        display: grid !important;
    }
}

#slideout_inner {
    background: #fbd171;
    /* bottom: 40px; */
    left: 25px;
    padding: 5px;
    position: fixed;
    text-align: left;
    transition-duration: .3s;
    /* top: 50%; */
    bottom: 40%;
}

.adclickbtn {
    cursor: pointer;
    top: 60%;
    bottom: 50%;
    position: fixed;
}

.feedbackkbtn {
    cursor: pointer;
    top: 75% !important;
    bottom: 50%;
    position: fixed;
}

aside {
    display: block;
    position: relative;
}
aside h3 {
    background: #369;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    height: 25px;
    line-height: 12px;
    padding: 5px 10px;
    transform: rotate(270deg);
    transform-origin: 0 0;
    white-space: nowrap;
}

.rsadwidth100{
    width: 100%;
}

.rightsideFixedBlock {
    z-index: 999;
    position: fixed;
    bottom: 40px;
    right: 0;
    width: 25px;
    padding: 0px;
    text-align: center;
    transition-duration: 0.3s;
}

.chatclickbtn {
    cursor: pointer;
    top: 64%;
    bottom: 50%;
    position: fixed;
}

.specialcontent{
    font-size: 11px !important;
    padding-left: 5%;
    padding-right: 5%;
}

.specialcontent span{
    font-size: 11px !important;
}

.acc {
    bottom: 14px;
}

.ux {
    transition: opacity 300ms ease 0s;
}

.ot {
    opacity: 1;
}
.dx {
    justify-content: center;
}
.db {
    position: fixed;
    z-index: 10000;
    /* margin-left: 50%; */
    /* margin-right: 50%; */
    width: 100%;
}
.o {
    display: flex;
}

.vb {
    height: 40px;
}
.va {
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px 0px;
}
.uz {
    padding: 0px 14px 0px 16px;
}
.sr {
    border-radius: 20px;
}
.dz {
    border: none;
}
.ao {
    align-items: center;
}
.o {
    display: flex;
}
.c {
    background-color: #82b6ff;
}

.vf {
    height: 12px;
}
.ve {
    margin: 0px 16px;
}

.vd {
    border-right: 1px solid rgb(230, 230, 230);
}
.l {
    display: block;
}
.ci{
    font-size: 12px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 600;
}


.css-xwo50m.css-xwo50m {
    height: auto;
    max-height: none;
    width: 770px;
    grid-template-rows: auto 1fr;
}

.css-xwo50m.css-xwo50m {
    background: rgb(255, 255, 255);
    height: 95%;
    width: 100%;
    display: grid;
    grid-template-rows: auto 3fr auto;
    justify-items: center;
    position: relative;
}

.css-11wtop9.css-11wtop9 {
    background-color: rgba(33, 41, 52, 0.75);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
    overflow: hidden;
    color: rgb(20, 30, 35);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10000;
}

.qc-cmp-cleanslate {
    font-size: medium;
    line-height: 1;
    direction: ltr;
    text-align: start;
    font-family: "Times New Roman", Times, serif;
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    list-style-type: disc;
}

.mySlides a > img {
    height: auto;
    margin: auto;
}

.css-xwo50m.css-xwo50m {
    height: auto;
    width: 300px;
    grid-template-rows: auto 1fr;
}

.hightlighted{
    font-weight: 800;
}

.acd{
    opacity: 0.8;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.acd:hover{
    opacity: 1;
}

.blogbox{
    height: 145px;
}

.blogdetpart {
    width: 100%;
    padding: 5px;
    float: left;
}

.blogauthor {
    /*
    font-size: 13px;
    font-style: italic;
    margin-top: 15px;
    bottom: 10px;
    */
    font-size: 13px;
    font-style: italic;
    margin-top: 0px;
    margin-left: 15px;
}

.single-app-features .app-features-content {
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 0%);
    height: 65px;
}
.ci{
    text-align: center;
}
.adbtn{

    width: 80px;
}

.chatbtn{
    width: 120px;
}

.feeedbackbtn{
    width: 80px;
}
.vf{
    margin: 0px;
}

.endline{
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.clickhereanim img{
    width: 75px;
}

.onlinecss{
    color: #007bff;
}


.undernotes {
    /* padding: 1px; */
    display: block;
    color: #007bff;
    font-weight: 600;
}

.undernotes_warning {
    padding: 6px; 
    display: block;
    color: red;
    font-weight: 600;
}


.btn.btn-pink-round {
    border-radius: 40px;
    background: #ff5994;
    border-color: #ff5994;
    color: #fff;    
}
 
.btn .icon.icon-round {
    border-radius: 50%;
}

.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
} 

.btn.btn-primary .icon i {
    color: #fff;
}

.btn .icon i {
    font-size: 20px;
}


.newactbtn {
    max-width: 165px;
    margin-top: 20px;
}

.newactbtn span{
    text-align: left;
    display: grid;
    font-size: 13px;
    font-weight: 600;
}

.btn .icon {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 30px;
    bottom: 2px;
    /* background: #fff; */
}

.iconbg{
    cursor: pointer;
}

.iconbg img {
    width: 35px;
    /* color: #000; */
    border-radius: 8px;
    padding: 7px;
    background-color: #fff;
}
.alignicons {
    display: inline-block;
    text-align: center;
    padding-left: 10px;
}
.icontext{
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    padding-top: 10px;
}

.ver_action_block {
    z-index: 997 !important;
    padding-top: 20px;
}

.listingad img{
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.table-card{
    width: 250px !important;
}

.generatebtn{
    padding:15px;
    text-align: right;
    width: 100%;
}

.bannerblock{
    display: block;
    position: relative;
    
    width: 100% !important;
    margin: 6px;
    margin-bottom: 30px;
}
.listingad{
    margin-bottom:20px;
}
.s_icon{
    cursor: pointer;
}
.searchbar{
    display: flex;
}

.searchgobtn{
    background-color:#82b6ff;
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #82b6ff;
    color:#ffffff;
}

.searchinputbox {
    border: 1px solid #82b6ff;
    border-radius: 20px 0px 0px 20px;
    font-size: 13px;
    padding: 8px;
    width: 245px;
    padding-left: 18px;
}

.locationlistonshowv5 {
    position: absolute;
    background-color: white;
    border: 1px solid #393999;
    border-radius: 10px;
    display: none;
    right: auto;
    /* top: 163px; */
    width: auto;
    left: auto;
    margin-top: 14px;
    margin-left: -14px;
}

.cursorpointer{
    cursor: pointer;
    text-align: center;
    font-style: normal;
    text-transform: lowercase;
}

.writereviewscs{
    width: 100%;
    text-align: center;
}
.hlink{
    color: blue;
    text-decoration: underline;
}

.locationlistonshowv4 {
    position: absolute;
    background-color: white;
    border: 1px solid #393999;
    border-radius: 10px;
    display: none;
    right: auto;
    /* top: 163px; */
    width: auto;
    left: auto;
    margin-top: 14px;
    margin-left: -14px;
    z-index: 99;
}
.homeexlprts img{
     width: 145px;
     height: auto;
}
.tile-stamp-img {
    
    width: 65px;
    position: absolute;
    z-index: 99;
}

.landing-tile:has(.tile-stamp-sec) {
    background-color: aliceblue;
    border: 1px solid pink;
}



.v4uimobile .grid-item {
    /* background-color: #DDD8D4; */
    background-color: #ffffffa1;
    padding: 20px;
    font-size: 30px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #00000059;
}

.searchblocicons{
    display: inline-block;
    padding: 10px;
    margin-right: 10px;
    vertical-align: middle;
    padding-top: 10px;
}

.searchblocicons i{
    font-size: 18px;
    color:#007BFF;
}

.preloadstep{
    background: hsla(0,0%,100%,.9);
    border: 2px solid #ededed;
    margin-top:20px;
    margin-bottom: 20px;
    padding:10px;
}

.preloadstep p {
    font-size: 14px;
    font-weight: 600;
}

.btnaligned{
    text-align: center;
    padding-top:20px;
}
/*
.tile-stamp-sec{
    position: absolute;
    top: -18px;
    width: 100%;
}

.stamp-view {
    width: 150px;
    position: absolute;
    top: -69px;
    left: 0px;
    transform: none !important;
}
*/

.griditem_communityicon img {
    height: 60px;
}

.tile-stamp-img {
    position: absolute;
    /* -webkit-transform: rotate(322deg); */
    /* transform: rotate(322deg); */
    width: 70px;
    z-index: 99;
    left: 4px;
    top: -5px;
}
.otherrow .price{
    width: 100%;
    display: flex;
}

#blogbannerimg  {
    float: right;    
    margin: 0 0 0 15px;
}
.articleContent{
    padding-left:20px;
    padding-right: 20px;
}
.artcileblogimage{
    width: 350px ;
}

.left-side-body{
    /* box-shadow: 4px 3px 11px #000;*/
    margin-bottom: 10px;
}

.btn-post{
    background-color: #f7d171 !important;
    border-color:#f7d171 !important;
    padding-left:10px;
    color:#000;
}

.btn-post:hover{
    color:#fff !important;
    border-color:#f7d171 !important;
}

.bottompostnav{
    margin-bottom: 50px;
    
}

.titleblock {
    margin: 15px;
    border-top: 1px solid lightgray;
}

.available{
    color:green;
    font-weight: bold;
}

.blogshortlink{
    font-size: 14px;
    padding-left:10px;
}

.blog-view-all {
    background-color: #53d6c2;
    border-radius: 22px;
    padding: 11px;
    width: 90%;
    color: white;
    text-transform: uppercase;
    font-weight: bolder;
}

.blog-view-all:hover{
    color: #291464e8 !important;
}

.mt80{
    margin-top: 80px
}

.linkinwarning{
    cursor: pointer;
}

.tile-stamp-img {
    left: 4px;
    position: absolute;
    top: -5px;
    width: 60px;
    z-index: 99;
}

.campbannersection {
    background-position-x:unset !important;
    background-repeat: round;
    height: 245px;
}

.review_head{
    cursor: pointer;
}

.autosugdrpdwn{
   
    background-color: #f25895;
    box-shadow: 4px 2px 6px #f25895;
}

.suggestion-item{
    background-color: #82b6ff;
    padding:5px;
    font-size: 13px;
    line-height: 13px;
    color:#ffffff;
    font-weight: bold;
}

.left-side-box .suggestion-item{
    background-color: #82b6ff;
    padding:5px;
    font-size: 13px;
    line-height: 13px;
    color:#000 !important;
    font-weight: bold;
}

.suggestion-item--active{
    background-color: #82b6ff;
    padding:5px;
    font-size: 13px;
    line-height: 13px;
    color:#000;
    font-weight: bold;
}



#citypopup .modal-content{
  border-radius: unset !important;
  background-color: unset !important;
}

#menupopup .modal-content{
  border-radius: unset !important;
  background-color: unset !important;
}

.citypopupinnerblock{
    background-color: #fff !important;
    border-radius: 6px;
    margin-bottom: 10px;
    margin-left:25px;
    margin-right: 25px;
}

.menupopupinnerblock{
      margin-bottom: 10px;
    margin-left:25px;
    margin-right: 25px;  
}

.citypopupinnerblock ul {
    list-style: none;
    margin: 0px;
    padding: 5px;
}

.citypopupinnerblock ul li{
    padding:5px;
    border-radius: 6px;
    font-size: 12px;

}

.citypopupinnerblock ul li.active {
    background-color: #ff5994;
    color: #ffffff;
    font-weight: 600;
}

.campbox .viewmore{

    font-size: 11px;
    font-weight: 600;

}

.footer-bottom-menu .bottom-menu .tab-nav-container .tab.active{
    border-top: 3px solid #f26c85 !important;
}
.cityhead{
    display: flex;
}

.cityhead {
    display: flex;
    background-color: #ff5994;
    border-radius: 6px 6px 0px 0px;
    color: #fff;
    padding: 10px;
    font-weight: bold;
}

/* mobile menu */
.mainMenu{
    width: 250px;
    display: block;
    border-radius: 10px;
    overflow: hidden;
}
.item{
    border-bottom: 1px solid #fff;
    overflow: hidden;
}
/*
.item a:hover{
    color: #fff !important;
}*/

.item a:hover{
    color: #000 !important;
}

.btnm{
    display: block;
    padding: 14px 15px;
    font-size: 12px;
    font-weight: 600;
    /*background-color: #ff5994;*/
    background-color: #ffffff;
    color: #000 !important;
    position: relative;

}
.btnm:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right:8px solid transparent;
    border-top:10px solid #ff5994;
    right: 15px;
    bottom: -10px;
    z-index: 9;
    
}
.btnm i {
    margin-right: 10px;
}
.subMenu{
    background: #ffffff;
    transition: max-height 0.7s;
    max-height: 0;
}
.subMenu a{
    display: block;
    padding: 14px 15px;
    color: #000;
    font-size: 12px;
    border-bottom: 1px solid #000 !important;
    position: relative;
    text-decoration: none;
    
}

.subMenu a:hover{
    /*
    background: #273057;
    background: -moz-linear-gradient(top, #273057 0%, #273057 50%, #394c7f 51%, #394c7f 100%);
    background: -webkit-linear-gradient(top, #273057 0%,#273057 50%,#394c7f 51%,#394c7f 100%);
    background: linear-gradient(to bottom, #273057 0%,#273057 50%,#394c7f 51%,#394c7f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#273057', endColorstr='#394c7f',GradientType=0 );
    transition: all 0.3s;
    border-bottom: 1px solid #394c7f;
    */
    transition: all 0.3s;
    border-bottom: 1px solid #000;
    color:#000 !important;
}

.subMenu a:last-child{
    border:none;
    border-bottom: none !important;
}
.item:target .subMenu{
    max-height: 300px;
    overflow-x: scroll;
    
}

.mainMenu {
    width: 100%;
    /* display: block; */
    border-radius: 10px;
    overflow: hidden;
    padding-left: 0;
}


.aftervrying {
    font-size: 12px;
    font-weight: 600;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 80px;
  height: 80px;
}

.css-xwo50m span{
    cursor:pointer;
    right: 0px;
    position: absolute;
    text-align: right;
    background-color: lightgray;
    top: -15px;
    padding-left: 4px;
    padding-right: 4px;
}

.bannerblock {
    height: 100% !important;
}


.menudropdown{
    border: 1px solid lightgrey;
    border-radius: 9px;
       bottom: 34px;
    position: absolute;
    left: 6px;
    text-align: left;
    background-color: white;
    list-style: none;
    font-size: 13px;
    padding-left: 10px;
    width: 240px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.menudropdown li{
    text-align: left;
    border-bottom: 1px solid #d3d3d33d;
    width: 218px;
}

.dropdown-item{
    text-align: left !important;
    font-weight: 600;

}
.submenu{
    display: contents;
}

.submenu li{
    padding-left:20px;
    font-size:13px;

}
.submenu li a{
    font-weight: normal !important;
}

.submenu-list{
    left:100px;
    padding-left: 0px;
}


.floating-menu-btn-listing {
    background: #fbd171;
    padding: 3px;
    width: 30px;
    z-index: 9;
    position: fixed;
    bottom: 44%;
    right: 0px;
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}


div.scrollmenu a i {
    color: #f26c85;
    font-size: 12px;
    font-weight: 800;
}

.floating-menu-btn-listing {
    display: none;
}

.footer-bottom-menu .bottom-menu .tab-nav-container .tab a i {
    font-size: 11px;
    color: #9b9d9f;
    padding-right: 8px;
    padding-left: 8px;
}

.icontext{
    font-family: "Montserrat", sans-serif !important;
    color:#000;
    font-size: 15px;
    text-transform: capitalize;
    padding-left:10px;
}

.filter-menu{
    display: block !important;
    padding: 9px !important
}

.submenu-list {
    left: 35px;
    padding-left: 0px;
    top: 11px;
}

.filter-pop-over .popover.show{
    top: -32px !important;
    left: 20px !important;
}

.lead-close-btn {
    top: 10px!important;
    right: 10px!important;
}


button.lead-close-btn {
 
    width: 50px;
    border: none;
    border-radius: 50%;
    height: 28px;
    position: absolute;
    right: -9px;
    float: left;
    top: -16px;
    z-index: 99;
    display: flex;
    align-content: center;
    justify-content: center;
    background: none;
    font-size: 15px;
}

.btnalignedright {
    text-align: right;
    padding-top: 20px;
}

.btn-sm, .btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 13px;
    line-height: 1.5;
    border-radius: 0.2rem;
    font-weight: 600;
}


.bewise-signin-block {
    padding-right: 0;
    
}

.home-sign-name .signned-name{
    color: #000;
}

.header-search{
    padding-left: 0px;
     
    margin-left: 3px;
}

.mt20onlydesktop{
    margin-top: 80px;
}

.suggestion-item span{
    background-color: #fff;
    color:#000;
    padding:5px;
    padding-left:0px;
    text-align: left;
    line-height: 20px !important;
}

.suggestion-item--active span{
    background-color: #fff;
    color:#000;
    padding:5px;
    padding-left:0px;
    text-align: left;
    line-height: 20px !important;
}
.smallnote{
    display: block;
    color:blue;
    
}

.smallnoteright{
    float: right;
    font-size: 14px;
    color: #000;
    text-transform: capitalize;
}

.mobileonly .smallnoteright {
    color: #000;
    /* float: right; */
    font-size: 14px;
    text-transform: lowercase;
    display: block;
    float: none;
}

.displayblock{
    display: block !important;
}

.externalbannerclick{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.externalbannerclick img{
    border: 1px solid #4052;
    /*border-radius: 10px;*/
    cursor: pointer;
    height: auto;
}

.wcicon img{
    width: 175px;
}

.cursorpointer{
    cursor:pointer;
}

.features-area h2{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
    color: #212529;
    border-bottom: 3px solid #ff5994;
    display: inline;
    width: auto;
    text-align: left;
}

.camp-author-img{
    height:100%;
}

.hZHCEI {
    height: calc(240px) !important;
    overflow-y: scroll;
    margin-top: 2px;
    padding-top: 6px;
}

.rsc-container {
    box-shadow: 0 -1px 15px 2px #d3d3d3!important;
    height: 300px!important;
    /* overflow-y: scroll !important; */
}

.rsc-footer{
    display: none;
}
.rsc-os-option-element {
    font-size: 10px!important;
    /* font-weight: 700!important; */
    padding: 5px!important;
}
.dgXnHu {
    animation: 0.3s ease 0s 1 normal forwards running gZmgDU;
    /* border-radius: 50% 50% 0px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    height: auto;
    width: 45px;
    padding: 3px;
    border-radius:0% !important;
    transform: scale(0);
    transform-origin: right bottom;
}
.external_affilates_section{
    clear: both;
    display: block;
    width: 100%;
    margin-top: 20px;
}
.block_bordered{
    background-color: #ffffff;
    box-shadow: #000;
    font-size: 13px;
    box-shadow: 4px 3px 10px #000;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 30px;
}
.simpleform{
    padding:10px;

}
.formsection{
    background-color:#ffffff;
    box-shadow: #000;
}
.resultsection{
    padding: 10px;
    color: green;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
}

.__react_component_tooltip {
    width: 200px;

}
.schooltypeinfo{
padding: 10px;
font-size: 13px;
font-weight: 600;
background-color: cornsilk;
}

.btn-darkblue{
    background-color: blue !important;
}

.btn-lightblue{
    background-color: rgb(14, 93, 211) !important;
}


.enrollbtnblock{
    width: 100%;
    text-align: center;
}

.btnfloatright{
    padding-top:5px;
}

.websiteurl{
    word-break: break-all;
}

.offer_left_text, .offer_right_text {
    float: left;
    font-size: 18px;
    margin-top: 10px;
    padding-left: 10px;
    width: 100%;
    font-style: italic;
    color: #000;
}

.loadinggif img{
    width: 60px;
}

.error_red {
    color: red;
    font-size: 15px;
    padding: 5px;
}

.sliderPrevNext button{
    background-color: #ff5994;
    border-radius: 5px;
    color: #fff;
    border: none;
    margin: 10px;
}

.topLogo img {
    text-align: center;
    /*
    vertical-align: middle;
    width: auto;
    height: 100px;
    max-width: 200px;
*/
    width: 200px;
    height: 80px;
    object-fit: contain;
}
.middleContExcl h5{
    text-transform: capitalize;
}

.exclprtrs-container {
    border: 1px solid rgba(0,0,0,.125);
    min-height: 280px;
    box-shadow: 1px 2px 10px #000;
    border-radius: 10px;
}
.exc_head_title{
    margin: 20px;
    margin-bottom: 50px;
}

.exclpartlist .slick-list{

    margin-left: auto;
    margin-right: auto;
}

.exclpartlist .slick-slider{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 35px !important;
    line-height: 1;
    opacity: 0.65 !important;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.homeexlprts {
    text-align: center;
    background-color: #F5F5F5;
    padding: 16px;
    height: 95px;
    border-radius: 10px;
    margin: 15px;
    width: 90%!important;
}

.slick-prev, .slick-next{
    z-index: 5;
}

.exclpartlist .slick-next {
    right: -9px;
}
.home-art{
    margin-bottom: 60px;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 18px !important;
    line-height: 40px  !important;

}

.ratingsblock>div {
    border: 1px solid grey;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px  !important;
}

.keyinfoscrollpad {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}

.aboutinfo {
    text-align: justify;
    font-size: 14px;
    margin-bottom: 50px;
}

.features-area {
    padding-top: 45px;
    padding-bottom: 30px;
}

.newicon {
    position: absolute;
    right: 10px;
    top: 10px;
}
.homeexlprts{
    position:relative;
}

.form_header{
    font-size: 19px;
    border-bottom: 4px solid #82b6ff;
    line-height: 38px;
    margin-bottom: 15px;
    font-weight: 600;
}


.btnfloatright{
    width: 100%;
    text-align: right;
    display: inline-block;
}

.form-inline .form-group{
    gap:5px;
}
.form-group div{
    padding-right: 10px;
}

.aurotable{
    width: 100%;
}

.aurotable td{
    width: 50%;
}

.successmsg{
    font-size: 16px;
    color:green;
}
.spanlink{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

.slick-dots li.slick-active button:before{
    color:#ff5994 !important;
}

.hlink{
    cursor: pointer;
}

.font14{
    font-size: 14px;
}


.borderwithcolor1{
    background-color: #daeaf1;
    border: 7px solid #c6dce4;
    display: flex;
    align-items: flex-start;
}

.displayflex{
    display: flex;
}

.displayflexbaseline{
    display: flex;
    gap:15px;
    align-items: baseline;
}

.detailBannerNoBg{
    background-color: #ffffff;
}

.per40image {
    width: 20%;
    text-align: center;
}

.orgphotofull {
    width: 60%;
}

.paddingleft20 {
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.landing-right-ad-245{
    width: 245px;
    height: 740px;
}



 
.flex-row-joinus {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.boxitemjoinus{
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    border: 2px solid #ededed;
    height: calc(100% - 2.0rem);
    text-align: center;
    min-height: 150px;
    padding: 15px;
    cursor: pointer;
}

.marginautolr{
    margin-left: auto;
    margin-right: auto;
}

.offer_row{
    display: block !important;
}

.fontweight600{
    font-weight: 600;
}

.text-join_text{
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
}

.text-join_link{
    text-transform: uppercase;
    font-weight: 600;
    color:#82b6ff;
    cursor: pointer;
}

.joinus_img{
    width: 100%;
    vertical-align: middle;
    padding:20px;
    display: inline-block;
}

.joinus_img img {
    width: auto;
}

/* landing page */
/* sep 2 2023 */

.vertical-next:before, .vertical-prev:before {
    font-size: 17px;
    line-height: 17px;
}

.vertical-next, .vertical-prev {
    position: relative;
    color: #ff5994;
    font-family: 'Montserrat';
    border: 1px solid #ff5994;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    left: auto;
    text-transform: capitalize;
    vertical-align: middle;
    height: 100%;
    align-items: center;
    gap: 6px;
    display: flex;
    width: 100%;
    font-size: 13px;
    line-height: 13px;
}

.pull-right {
    float: right;
    /*display: flex;*/
}

.disflex{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.bootstrap-select>.dropdown-toggle:after {
    margin-top: -1px;
    color: #000;
}

.landing-sort-drop > .btn-light.dropdown-toggle, .landing-sort-drop > .btn-light:not(:disabled):not(.disabled):active {
    background-color: #d3d3d363 !important;
}

.landing-sort-drop > .btn-light.dropdown-toggle.bs-placeholder, .landing-sort-drop > .btn-light.dropdown-toggle {
    color: #fff !important;
    padding-bottom: 3px !important;
    background-color: lightgray;
    border-radius: 25px;
}

.topfilterflexbar {
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.flx_breadcrum{
   width: 50%;
   font-size: 12px;
}
.flx_sortby{
    width: 50%;
    font-size: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.flx_soryby_inner{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}

.disflex div{
    width: 100%;
}

.preloadstep p {
    font-size: 14px;
    font-weight: 600;
    color: #007bff;
}

.btn-primary {
    color: #fff;
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.paginations {
    padding-top: 10px;
    padding-bottom: 10px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.no-padding {
    padding: 0px !important;
    margin-bottom: 20px;
}

.tile-distance, .tile-rate{
    color: #000 !important;
    background-color: #fff !important;
}

.tile-compare, .tile-wish, .tile-distance, .tile-rate, .tile-details label {
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
}

.tile-stamp-img {
    left: 4px;
    position: absolute;
    top: -5px;
    width: 60px;
    z-index: 99;
    transform: rotate(322deg);
}

.tuitionavatar{
    text-align: center;
}

.centeravatar img{
    width: 200px;
    padding: 25px;
    
}

.tuitionavatar img{
    width: 200px;
    padding: 25px;
    
}


.actionbutv2 {
    padding: 10px;
    border: 1px solid #82b6ff;
    border-radius: 5px;
    font-size: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #000;
    cursor: pointer;
}

.actionbutv2:hover{
    cursor: pointer;
    color:#ffffff;
    background-color: #007bff;
}
.bewiseratingsv1{
        display: flex;
        flex-direction: row;
        align-items: center;
        color:#ffffff;
}
.googleratingsv1{
    display: flex;
    flex-direction: row;
    align-items: center;
    color:#ffffff;
}
.dv-star-rating-star i{
    font-style: normal;
    font-size: 20px;
}

.dv-star-rating {
    display: inline !important;
    padding-left: 10px;
}

.org_name {
    font-size: 18px;
    font-weight: 600;
    line-height: 50px;
    padding-left: 20px;
}
.org_address {
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
}
.actionicons{
    padding-left: 20px;
}
.onDemandInfo h3 {
     
    padding: 5px;
    color: #fff !important;
    /* border-bottom: 4px solid #007bff !important ;*/
}
.btnblock.active {
    border-bottom: 4px solid #007bff;
}
.main_info{
    padding-left: 20px;
    padding-right: 20px;
}
.orgfollows{
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.likes{
    color:red;
    font-family: 'Montserrat';
    display: flex;
    align-items: baseline;
    gap: 5px;
}
.follows{
    display: flex;
    align-items: baseline;
    gap: 5px;
}

.dv-star-rating-star i {
    font-style: normal;
    font-size: 26px;
}


.grid-container {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-container-3 {
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.paddingtop16{
    padding-top: 16px;
}  

.grid-item {
   /* background-color: #DDD8D4;*/
    background-color: #ffffffa1;
    padding: 20px;
    font-size: 30px;
    text-align: center;
    border-radius: 10px;
    width: 100%;
    height: 100%;

  }

.griditem_text{
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 600;
    
}
.sliderText{
    text-align: center;
    font-size: 35px;
    font-family: 'Montserrat';
    color: #fff;
    font-weight: 600;
    padding-top: 60px;
    text-shadow: 0 0 1.15em #000;
}

.locationbox{
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 100px;
}

.citydefault{
font-size: 21px;
color: #000;
font-weight: 600;
background-color: white;
padding-left: 10px;
padding-right: 10px;
border-radius: 10px;
padding-top: 5px;
padding-bottom: 5px;
display: inline-block;
}


.whatstrending .wt_row{
    padding-bottom: 10px;
}
.whatstrending .blogline{
    width: 60%;
}

.sliderFirstPara h1 {
    font-size: 23px;
    margin-bottom: 0px;
    line-height: 32px;
}


.locationtext{
    font-weight: 600;
    text-transform: capitalize;
}

.exl_partners .slick-next {
    right: -10px;
}

.searchbar input{
    border: 1px solid #82b6ff;
    border-radius: 20px 0 0 20px;
    font-size: 13px;
    padding: 8px 8px 8px 18px;
    width: 245px;
}


.tilesarea_sliderv4 {
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 45px;
    padding-top: 370px;
}


.floatlefttray {
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    display: block;
    margin: 0;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 50%;
    box-shadow: 5px 3px 5px pink;
    z-index: 999;
}

.btm20px{
    padding-bottom: 15px;
}
.bcitem {
    text-transform: capitalize;
}

.overflowscrollx{
    overflow-x: scroll;
}

.tilesarea_sliderv4 .grid-item {
    /* background-color: #DDD8D4; */
    background-color: #fffffff2;
    /* padding: 20px;*/
    font-size: 30px;
    text-align: center;
    border-radius: 10px;
}
.locationlistonshowv4 ul li:hover{
    color: #fff;
    background-color:#007bff;
}

.locationlistonshowv5 ul li:hover{
    color: #fff;
    background-color:#007bff;
}


.locationlistonshow ul{
    list-style: none;
    padding: 10px;
}

.locationlistonshow ul li{
    color: #000;
    font-size: 15px;
    font-family: 'Montserrat';
    text-align: left;
    padding:5px;
    border-radius: 10px;
}

.locationlistonshow ul li:hover{
    color: #fff;
    background-color:#007bff;
}

.newicon img{
    width: 32px !important;
    height: 32px !important;
}


.homeexlprts{
    height: 150px !important;
}



.locationlistonshow{
    position: fixed;
    background-color: white;
    border: 1px solid #393999;
    border-radius: 10px;
    display:none;
    right: auto;
    top: 70px;
    width: 10%;
    left: auto;
}

/*
.locationdropdown{
    background: #82b6ff;
    color: white;
    display: inline-block;
    padding: 8px;
    border-radius: 25px;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 10px;
    cursor: pointer;
}*/

.popup-content  span{
background-color: white;
font-size: 12px;
padding: 10px;
border-radius: 10px;
margin-left: 0px;
padding-left: 10px;
}

.locationonscroll_block{
        display: inline-block;
        padding: 10px;
        margin-right: 10px;
        vertical-align: middle;
        padding-top: 10px;
}

.locationonscrollitem{
    display: none;
}

.locationonscrollitem .locationdropdown{
    color:#007bff;
    display: inline-block;
    padding: 0px;
    border-radius: 25px;
    font-size: 16px;
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    margin-right: 5px;
    cursor: pointer;
    text-decoration: underline;
    text-transform: capitalize;
}


.tickers {
    padding: 20px;
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.locationonscroll_block .popup-content span {
    background-color: white;
    color:#000;
    border:1px solid #000;
    font-size: 12px;
    padding: 10px;
    border-radius: 10px;
    margin-left: 0px;
    padding-left: 10px;
}

.locationdropdown{
    color: white;
    display: inline-block;
    padding: 0px;
    border-radius: 25px;
    font-size: 20px;
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    margin-right: 5px;
    cursor: pointer;
    text-decoration: underline;
    text-transform: capitalize;
}

  .tilesarea {
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 40px;
    padding-top: 40px;
}
  .tilesarea_slider{
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 45px;
    padding-top: 60px;
  }


  .benefits{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .profitfromus{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .griditem_icon img{
    width: 100%;
  }

  .griditem_icon_wc img{
    width: 160px;
    height: 40px;
  }

  .flexitems{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .newtitle_home{
    font-family: 'Montserrat';
    text-align: center;
    font-size: 30px;
    color: #007BFF;
    font-weight: 600;
    padding-bottom: 10px;
 }

 .grid-item-benefits{
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px lightgray;
 }

 .benefits .griditem_text{
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
 }
 .griditem_icon_wc{
    height: 50px;
 }

 .flex-container-4{
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
 }

 .flex-item-howtouse {
    border-radius: 5px;
    box-shadow: 0 0 0 1px #d3d3d3;
    padding: 25px;
    width: 275px;
    height: 100%; /*260px;*/
}

 .flex-item-howtouse img {
    /* height: 50px; */
    width: 75px;
    height: auto;
}

.flex-item-howtouse-arrow img{
    width: 30px;
    height: auto;
}

.howtouse .griditem_para{
    text-align: center;
    font-size: 13px;
}
.flex-item-howtouse .griditem_icon{
    min-height: 105px;
}

.articleblogs {
    position: relative;
    margin-left: 15%;
    margin-right: 15%;
}

.floatstar{
    position:absolute;
    right: 0px;
}

.flexcolumntwo{
    display: flex;
    flex-direction: row;
}

.sliderposts{
    width: 50%;
}

.whatstrending{
    font-size: 12px;
    font-weight: 600;
}

#ratings_section .dv-star-rating {
    display: inline !important;
    padding-left: 4px;
}

#ratings_section .dv-star-rating-star i {
    font-style: normal;
    font-size: 14px;
}

.blogitem{
    width: 300px;
    padding: 10px;
}
.blogimg img {
    border-radius: 10px;
    width: 100%;
}
.blog_middle{
    padding-left: 100px;
    padding-right: 100px;
}

.flexcolumntwo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
}

.whatstrending {
    font-size: 12px;
    font-weight: 600;
    padding-left: 30px;
}
.smalltitles_blue {
    font-family: 'Montserrat';
    text-align: left;
    font-size: 16px;
    color: #007BFF;
    font-weight: 800;
    text-decoration: underline;
}
 .floatstar {
    position: absolute;
    right: 18px;
    top: 17px;
}

.floatstar img{
    width: 60px;
}

.blogdetail{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
}

.title_small{
    font-size: 15px;
    font-weight: 600;
    font-family: 'Montserrat';
}

.plainlink{
   border: 1px solid #007bff;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #007bff; 
}

.linkbtn{
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    background-color: #007bff;
    color:#ffffff;
}

.wt_row_title{
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 5px;
}
.wt_row_para{
    font-size: 15px;
    font-weight: normal;
}

.wt_link {
    font-family: 'Montserrat';
    font-size: 13px;
    color: #007bff;
    padding-top: 10px;
    text-decoration: underline;
    padding-bottom: 15px;
}

.articleblogs {
    position: relative;
    margin-left: 15%;
    margin-right: 15%;
    padding-top: 35px;
}

.adimg{
    text-align: center;
    padding-top: 15px;
    padding-bottom: 30px;
}
.adimg img{
    width: 980px;
}

.flexrow{
    display: flex;
    gap:20px;
    flex-direction: row;
    align-items: center;
}


.orginfo{
    width: 70%;
}

.oneAd {
    background-color: #fff;
    height: 100%;
    text-align: center;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 12px;
    padding: 15px;
    width: 100%;
}

.dv-star-rating label{
    margin-bottom: 1px;
}

.dv-star-rating-star i{
    font-size: 14px;
}

.dv-star-rating {
    display: inline !important;
    padding-left: 0px;
}

.paddingleft20 {
    padding-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ads-sction2{
    height: 100%;
}

.ads-sction2.insighttopad img, .ads-sction2.viewtopad img {
    height: auto;
    width: 100%;
}

.paddingleft10{
    padding:10px;
    padding-left:20px;
}

.orginfo {
    width: 100%;
}

.org_address {
    font-size: 12px;
    font-weight: 600;
    padding-left: 20px;
}

.actionicons {
    padding-left: 25px;
}

.breadcrumbsv2 {
    /* margin-left: 40px; */
    /* margin-top: 20px; */
    display: flex;
    margin-left: 20px;
    margin-bottom: 10px;
}


.acd {
    opacity: 0.5;
}

.howtouse{
    padding-top: 60px;
}

 
.exclusivepartner img {
    max-width: 185px;
    /* max-height: 200px; */
    height: 80px;
    /* width: inherit; */
    object-fit: scale-down;
    text-align: -webkit-center;
    margin-left: auto;
    margin-right: auto;
}

  .sliderblock .textsection{
    text-align: center;
    padding-top: 10px;
  }

  .sliderblock {
    padding-top: 10px;
    padding-bottom: 30px;
    position: relative;
    margin-left: 20%;
    margin-right: 20%;
}

.exclusivepartner {
    width: 300px !important;
    height: 225px;
    padding: 20px;
    /*box-shadow: 0px 2px 5px 0px lightgray;*/
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 10px;
    display: inline-block;
}
.image_section{
    display: inline;
}

.excl_name{
    padding-bottom: 10px;
}

.excl_name a{
    color: #000;
}

.v2ui .slick-dots li.slick-active button:before{
    color: #007bff !important;
}

.search_section{
    margin-left: 20%;
    margin-right: 20%;
}

.v2ui .searchGo{
    background: #007bff !important;
}

.justflex{
    justify-content: center;
    align-items: center;
}

.v2ui .cityselect select {
    border: none;
    background: 0 0;
    padding: 16px;
    /* text-decoration: underline; */
    font-size: 14px;
    padding-left: 14px;
    padding-right: 0;
    font-weight: 600;
    font-family: 'Montserrat';
}


.detailBanner{
    
    background-size: cover;
    background-position: center;
}


.per40image {
    max-width: 150px;
    height: auto;
}

.displayflex {
    display: flex;
    /* gap: 15px; */
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.flexgrow{
    flex-grow: 1 !important;
}

.orginfo{
    color: #ffffff;
}
.org_address{
    color: #ffffff;
}

.actionbutv2{
    background-color: #ffffff;
}

.ver_detail_block {
    margin-top: 0;
    /* margin-left: 40px; */
    margin-left: 10px;
    padding: 25px;
    padding-top: 10px;
}

/* sep 17 */
.bewiseratingsv1, .googleratingsv1{
    font-size: 13px;
    font-weight: 600;
}

.bcitem span{
    color: #FFF;
}
.bcitem_div{
   background-color: #fff;
}

.exclprtnblock .bcitem span{
    color: #000;
}
.exclprtnblock .bcitem_div{
    content: none;
    height: unset;
    margin: unset;
    width: unset;
    background: unset;
}


.follows{
    color: #ffffff;
}

.follows i{
    color:#fff; 
}

.newtile-img{
    max-width: 125px;
    height:auto;
}
.newtile-img img{
    width:100%;
}

.newtile{
    background: hsla(0,0%,100%,.9);
    border: 2px solid #ededed;
    box-shadow: 0 0 10px rgb(0 0 0/7%);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,.07);
    -webkit-box-shadow: 0 0 10px rgb(0 0 0/7%);
    height: calc(100% - 1.5rem);
    margin-bottom: 40px;
}
.rowflex {
    display: flex;
    gap: 20px;
}
.tilerow{
    width: 100%;
}
.newtile-info{
    flex-grow: 1;
}
.premiumstamp {
    width: 55px;
}
.premiumstamp img{
    width: 100%;
}
.newtile{
    position: relative;
}
.newtile .tile-detail-sec.tile-title p {
    font-size: 12px;
    font-weight: 800;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap!important;*/
}

.newtile .tile-detail-sec.tile-adrs p {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newtile .tile-detail-sec.tile-adrs p, .tile-details p {
    font-size: 10px;
}

.positionabsolute {
    position: absolute;
    top: 85px;
    left: 70px;
}

.positionabsoluteinmobile{
    position: absolute;
    top: 100px;
}

.premiumuser .newtile{
    background-color: #f0f8ff;
    border: 1px solid pink;
}

.card-skeleton{
    display: flex;
    flex-direction: row;
    gap:10px;
    width: 100%;
    height: 100%;
}

.right-col{
    flex: 1;
}


.orgbg {
    background-size: cover;
    height: 140px;
    width: 100%;
    background-position: top;
}
.mobileview .rowflex{
    flex-direction: column;
    gap: 5px;
}

.mobileview .newtile-info {
    flex-grow: 1;
    padding: 10px;
}

.orgimg_desktop{
    background-size: cover;
    height: 140px;
    width: 100%;
    background-position: top;
}

.inst_displayflex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.org_name {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    padding-left: 20px;
    padding-bottom: 10px;
}

.middlelist .pull-right{
    display: flex;
    align-items: center;
    justify-content: end;
    padding-top: 10px;
}

.articleContent h2 ~ p img:nth-child(even) {
    padding-right: 10px;
    width: 500px;
    height: auto;
    padding-bottom: 15px;
}

.articleContent h2 ~ p img:nth-child(odd) {
    padding-left: 10px;
    width: 500px;
    height: auto;
    padding-bottom: 15px;
}

/*
.articleContent h2 ~ p img:nth-child(even) {
    float: left;
    padding-right: 10px;
    width: 500px;
    height: auto;
    padding-bottom: 15px;
}

.articleContent h2 ~ p img:nth-child(odd) {
    float: right;
    padding-left: 10px;
    width: 500px;
    height: auto;
    padding-bottom: 15px;
}
*/
.articleblock.maincontentblock h2 ~ p:first-child img
{
    float: right;
}

.toast-warning .toast-message{
    color:red;
    font-weight: 600;
}

/*
.articleContent img:nth-child(even) {
    float: left;
    padding-right: 10px;
    width: 500px;
    height: auto;
    padding-bottom: 15px;
}

.articleContent img:nth-child(odd) {
    float: right;
    padding-left: 10px;
    width: 500px;
    height: auto;
    padding-bottom: 15px;
}
*/

.custome-tab-login li.react-tabs__tab--selected{
    background-color: #0118CA;
    color: #ffff !important;
    border-radius: 5px 5px 0px 0px;
    width: 50%;
}

.twosectionflex{
    display: flex;
    gap: 20px;
    justify-content: center;
     
}



.partnersection_flex{
    box-shadow: 0px 0px 0px 1px lightgray;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 50%;
    padding:10px;
    height: auto;
}

.partnersection_flex .text_right{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.teachersection_flex .text_right{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.text_right .clicktojoin{
    display: inline-flex;
    height: 100%;
    align-items: end;
}


.teachersection_flex{
    box-shadow: 0px 0px 0px 1px lightgray;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 50%;
    padding:10px;
    height: auto;
}

.fleximgleft{
    width: 270px;
    border-radius: 10px;
    height: auto;
}


.header-signin-icons i {
    font-size: 25px;
}


.text_right{
    font-size: 14px;
}

.sb_title{
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 12px;
    padding-top: 12px;
}
.displayflex{
    display: flex;
    gap:10px;

}

.displayflex .blogimg{
    width: 40%;
}

.smalltitles_blue {
    padding-top: 10px;
    padding-bottom: 10px;
}

.exl_partners{
    text-align: center;
}

.searchPopup{
    position: fixed;
    background-color: white;
    padding: 10px;
    margin: 20px;
    border-radius: 20px;
    text-align: left;
    max-width: 980px;
    width: 100%;
    height: 425px;
    z-index: 999;
}

.search_keyword{
    color: #0118CA;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    font-family: 'Montserrat';
}
.search_advanced{
    padding-top: 20px;
    padding-left:10px;
    font-size: 18px;
    text-align: left;
    font-family: 'Montserrat';
}


.states_selection ul {
    list-style-type: none;
  }
  
.states_selection  li {
    display: inline-block;
  }
  

  
  .states_selection label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
    cursor: pointer;
  }
  
  .states_selection label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .states_selection label img {
    height: 140px;
    width: 140px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  /*
  .states_selection input[type="radio"][id^="radio"] {
    display: none;
  }

  .states_selection input[type="radio"]:checked  + label {
    border-color: #ddd;
  }
  
  .states_selection input[type="radio"]:checked + label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
  }
  

.states_selection input[type="radio"]:checked + label {
    border: solid 2px green;
    transform: scale(0.9);
    z-index: -1;
  }
  */

  .float_right{
    float: right;
    width: 100%;
  }
  .boxed label {
    display: inline-block;
    width: 160px;
    padding: 10px;
    border: solid 2px #ccc;
    transition: all 0.3s;
  }

  .li_selection span{
    text-align: center;
    display: block;
  }

  .search_keyword_label{
    padding:10px;
    text-align: center;
  }

  .gosearch{
    text-align: center;
    padding-top: 20px;
  }


.boxed input[type="radio"] {
    display: none;
  }
  
  .boxed input[type="radio"]:checked + label {
    border: solid 2px green;
  }
.searchpage_top_bar div{
    display: inline;
}
  .searchpage_top_bar span{
    font-size: 16px;
    color: #000;
    font-weight: 600;
    display: inline-block;
    padding-right: 10px;
    
  }

.search_input{
    display: inline-block;
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 10px;
}

.rs-stack{
    margin-top: 7px;
}

.mainsearchareatopnew{
    /*padding-bottom: 100px;*/
    padding-bottom: 5px;
}

.rs-picker-tag-wrapper{
    text-align: left;
    margin-top: 5px;
}

.mob-col-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 9px;
}

/*
.arrowanimated{
    width: 40px;
}
*/

.comingsoontext1{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 600;
}

.comingsoontext2{
    font-family: 'Montserrat';
    font-size: 14px;

}

.comingsoontext3{
    font-family: 'Montserrat';
    font-size: 19px;
    color:#007bff;
    font-weight: 600;
    padding:10px;
}

.arrowanimated {
    width: 85px;
    height: 17px;
}

.tickers{
    padding: 20px;
}

.searchinline{
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    gap:10px;
}

.inputselectlocation{
    width: 25% !important;
}

.inputselectlocation select{
    padding: 8px;
    border: none;
    background-color: #fff;
    width: 100%;
}

.search_input{
    width: 50%;
    padding:5px;
}

.search_input .autocomplete-dropdown-container{
    position: relative;
    width: 70%;
}

.rightborder{
    border-right: 1px solid #555;
}

.s003 form .inner-form .input-field input{
    font-family: 'Montserrat';
    font-weight: 600;
}

.adimg {
    height: 250px;
    width: 100%;
    display: block;
    text-align: center;
}

.adimg img{
    width: 1200px !important;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
}

.warninglocation{
    font-family: 'Montserrat';
    padding:5px;
    color: red;
    font-size: 13px;
    font-weight: 600;
}
.warninglocation span{
    color:#000;
}

.autocomplete-dropdown-container{
    z-index: 4;
}

.curpointer{
    cursor: pointer;
}

.before-wrap{
    width: 68px;
    background-color: #007bff !important;
    border-radius: 25px 0px 0px 25px;
    text-align: center;
}

.before-wrap img{
    text-align: center;
    padding: 10px;
    width: 50px;
    height: 50px;
}


.notearrow{
    font-family: 'Montserrat';
    padding-left: 45px;
    color:#fff;
    font-weight: 600;
}

.notearrow img{
    width: 82px;
    transform: rotate(270deg);
    z-index: 3;
    position: relative;
}
/*
.girlchildbg{
    
    background-size: contain;
    background-position: 425px 6px;
    background-repeat: no-repeat;
    padding-bottom: 100px;
}
*/
/*
.girlchildbg {
    background-size: 500px;
    background-position: 300px -15px;
    background-repeat: no-repeat;
    padding-bottom: 50px;
}*/


.girlchildbg {
    background-size: 430px;
    background-position: 400px -25px;
    background-repeat: no-repeat;
    padding-bottom: 50px;
}

.flex-item-howtouse{
    background-color: #ffffff;
    opacity: 0.7;
}


.flex-item-howtouse:hover{
    background-color: #ff5994;
    opacity: 1;
    color: white;
}
 
.grid-item:hover{
    background-color: #ff5994;
    opacity: 1;
    color: white;
}

.grid-item:hover img{
    filter: brightness(0) invert(1);
}

/*
.grid-item:hover{
    background-color: #ff5994;
    opacity: 1;
    color: white;
}

.grid-item:hover img{
    filter: brightness(0) invert(1);
}
*/

.viewallcentered{
    display: block;
    width: 100%;
    text-align: center;
}

.sliderposts{
    display: flex;
    flex-direction: column;
}

.sliderposts .slick-dots{
    position: relative;
    bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.flex-item-howtouse:hover img{
    filter: brightness(0) invert(1);
}

.newHeadh2 span{
       
        font-weight: 600;
        margin-bottom: 0;
        /*
        color: #212529;
        border-bottom: 3px solid #ff5994;
        */
        color: #007BFF;
        border-bottom: 3px solid #ff5994;
        display: inline;
        width: auto;
        text-align: left;
}

.sliderFirstPara h1{
    font-size: 23px;
}

.v4uimobile .howtouse .tilesarea{
    padding-top:160px;
}


.newuifooter .bottom-menu .tab-nav-container .tab.active a span{
    font-weight: 700 !important;
    color: #ff5994 !important;
}
.newuifooter .bottom-menu{
    border-top: 1px solid #ff5994;
}

.newuifooter .bottom-menu .tab-nav-container .tab.active a span small {
    color: #ff5994 !important;
}

.signup-right .newlogin-email-text{
    padding-top: 25px;
    display: block;
}

.signup-right .react-tabs{
    margin-top: 50px;
}

.cursorpointer{
    cursor: pointer;
}

.homefooter img{
    width: 100% !important;
    height:auto !important; 
}
.pageTitleH1{
    padding: 10px;
}

.pageTitleH1 H1{
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
}

.sliderFirstPara h1 {
    font-size: 23px;
    display: contents;
}

.sliderFirstPara .cityline {
    display: inline-block;
}


.communityicon img {
    width: 100%;
    height: 70px;
}

.blogimg img{
    width: 314px;
    height:177px;
}


.blogimg_sm img{
    width:250px;
    height:140px;
}

.breadcrumbsv2 {
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;
    align-items: center;
}

.exclprtnblock .bcitem span{
    text-transform: capitalize;
}

.bcitem_div i{
    padding: 5px;
}

.lookupform{
    background-color: #1F497D;
    padding:20px;
    color:white;
    font-size:12px;
    padding-bottom: 30px;
}

.lookupform .form-control{
    height: 30px;
    line-height: 25px;
    font-size: 12px;
}

.lookupform label{
    margin-bottom: 5px;
    margin-top: 5px;
}

.displayBlock{
    display: block;
    text-align: center;
}

.displayFlex{
    display: flex;
    flex-direction: column;
    gap:20px;
}

.card-skeleton-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5%;
}


.home6{
    background-color: white;
}

.home5{
    background-color: lightblue;
}

.sliderFlex {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.home6 .search_section{
    margin:0px;
}
.home6 .tilesarea_sliderv4{
    padding:0px;
    padding-bottom: 45px;
    padding-top: 45px;
}

.left_flex_home_slider img{
    margin-top: 40px;
}

.home6 .sliderFirstPara{
    color:#000;
    background-color: unset;
}
.home6 .sliderFirstPara .cityline{
    color:#000;
}
.home6 .locationdropdown{
    color:#000;
}
.chooseeasyoption{
    display: block;
    margin-bottom: 10px;
}
.curtypeoption{
    display: inline;
    padding: 5px;
    background-color: rgb(150, 147, 144);
    border-radius: 5px;
    margin-right: 15px;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    color:#ffffff;
    cursor: pointer;
}

.curtypeoption.selected{
    display: inline;
    padding: 5px;
    background-color: #007bff;
    border-radius: 5px;
    margin-right: 15px;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    color:#fff;
}
.itslabel{
    font-size: 15px;
    font-weight: 600;
    padding-right: 10px;
    color:#000;
}

.fa-share-nodes {
    color: #0c56d4;
    font-size: 25px;
    padding: 5px;
}

.cur_displayflex{
    display: flex;
    flex-direction: row;
}

@media (min-width: 768px){
.pl-md-2, .px-md-2 {
    padding-left: 25px !important;
}
}

.centered{
    text-align: center;
    width: 100px;
}

.loader{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /*background: url('loadingtrans.gif') 
                50% 50% no-repeat rgb(249,249,249);*/
}

.autofillinputbox ul{
    list-style: none;
    padding-left:0px;
    margin: 0px;
    text-align: left;
}

.newlogin-email-text {
    padding-top: 25px;
    display: block;
    font-size: 17px;
}

.autofillinputbox>div{
    padding-right: 0px;
}

.autofillinputbox ul li{
    font-weight: 500;
    text-align: left;
}

.viewallblock{
    display: inline-block;
    padding-top: 50px;
}

.searchpagetitle{
    padding-top: 10px;
    padding-bottom: 13px;
    font-size: 20px;
}

.scrollx{
    width: 100%;
}

.scrollx ul{
    list-style: none;
    display: table-footer-group;
}

.scrollx ul li{
    float: left;
    font-size: 14px;
    background-color: lightgrey;
    font-weight: 600;
    color: #000;
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    min-width: 230px;
    text-align: center;
}

.smalltitle{
    font-size: 16px;
    font-weight: 600;
    margin-left:15px;
}

.exc_head_title {
    margin: 10px;
    margin-bottom: 25px;
}

.categoryblock {
    padding-left: 0px;
    padding-bottom: 20px;
}

.catselected{
    background-color: #007bff !important;
    color:#ffffff !important;
}

.features-area {
    padding-top: 20px !important;
}

.row.landing-tile {
    max-height: 270px;
}

.wt_row{
    margin-top: 10px;
    margin-bottom: 20px;
}

/* card flips Feb 27*/ 


  .card-vertical{
    height:165px;
    width:100%;
    -moz-transform-style:preserve-3d;
    -webkit-transform-style:preserve-3d;
    transform-style:preserve-3d;
    transition:all .4s ease;

   }

  .card-front, .card-back{
    color:#fafafa;
    height:100%;
    width:100%;
    position:absolute;
    font-size: 15px; 
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style:preserve-3d;
    -moz-transform-style:preserve-3d;
    transform-style:preserve-3d;
    padding:20px;
    border-radius: 16px;
   
   }
   
  .card-back{
    -moz-transform:rotateY(179.9deg);
    -webkit-transform:rotateY(179.9deg);
    transform:rotateY(179.9deg);
    background: linear-gradient(180deg,#ff5994,#e09eb5);
    border-radius: 10px;
    padding:20px; 
   }

  .card-vertical:hover{
    -webkit-transform:rotateY(179.9deg);
    -moz-transform:rotateY(179.9deg);
    transform:rotateY(179.9deg);
  }

  
.grid-item-card {
    /* background-color: #DDD8D4;*/
     background-color: #ffffff;
     font-size: 30px;
     text-align: center;
     border-radius: 10px;
     width: 100%;
     height: 100%;
 }

 .grid-item-card .griditem_text{
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
    color:#000;
}

.grid-item-m-card {
    /* background-color: #DDD8D4;*/
     background-color: #ffffff;
     font-size: 30px;
     text-align: center;
     border-radius: 10px;
     width: 100%;
     height: 100%;
     box-shadow: 0 4px 10px rgba(0,0,0,.25);
 }
 .grid-item-m-card .griditem_text{
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
    color:#000;
}
.grid-item-m-card {
    /* background-color: #DDD8D4;*/
     background-color: #ffffff;
     font-size: 30px;
     text-align: center;
     border-radius: 10px;
     width: 100%;
     height: 100%;
     box-shadow: 0 4px 10px rgba(0,0,0,.25);
 }
 .grid-item-m-card .griditem_text{
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
    color:#000;
    text-align: left;
}

.grid-item-m-card .card-back{
    -moz-transform:rotateY(179.9deg);
    -webkit-transform:rotateY(179.9deg);
    transform:rotateY(179.9deg);
    /*background: #1f3087c9;*/
    background: #ff5994;
    border-radius: 10px;
    padding:20px; 
}

   .font16{
    font-size: 16px;
    font-family: 'Montserrat';

}   

/*

@keyframes shine{
  0%{
    background-position: 0
  }
  60%{
    background-position: 200px
  }
  100%{
    background-position: 200px
  }
}
*/

.totalrecordsinfo {
    color: #f26c85;
    font-size: 10px;
    padding-left: 0px;
    display: flex;
}

.articleblock div .slick-slider .slick-next {
    right: 5px;
    top: 40%!important;
}

.articleblock div .slick-slider .slick-prev {
    left: -2px;
    top: 40%!important;
}

.blogpages_text{
    font-size: 13px;
}

.justifycontent_space{
    justify-content: space-between;
}

.campbox{
    width: 300px;
    height: 314px;
    border-radius: 30px;
    background-color: #ffffff;
    border:1px solid lightgray;
}
.camprating{
    text-align: right;
}
.imageblockbordered{
    border-radius: 10px;
}
.imageblockbordered img {
    width: 100% !important;
    height: 140px;
    border-radius: 10px;
}

.titleandratingblock{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.camptitle{
    font-size: 16px;
}

.camps_slider{
    text-align: center;
}

.campdescnew{
    font-size:12px;
    text-align: left;
}

.camplinkdate {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fromdatecamp span{
    color:#000;
    font-weight:600;
}
.fromdatecamp{
    font-size: 12px;
}
.viewmorecamp{
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 30px;
    padding: 6px;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 600;
}


/* flip card*/
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    background-color: transparent;
    cursor: pointer;
    width: 250px;
    height: 150px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }


  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #fff;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    /* height: auto; */
    border-radius: 20px;
    padding:10px;
    padding-top: 30px;
}
  
  /* Style the back side */
  .flip-card-back {
    background: linear-gradient(180deg,#ff5994,#e09eb5);
    color: white;
    transform: rotateY(180deg);
    border-radius: 20px;
    padding:10px;
    padding-top: 5%;
  }

  .card-back-text{
    font-size: 14px;
  }

  .inst_name {
    width: 55%;
    }

  .inst_action_btns{
    width: 45%;
    justify-content: end;
  }

  .h1css{
    font-family: Montserrat,sans-serif!important;
    font-size: 19px;
    line-height: 32px;
    margin-bottom: 0;
  }

  .fullsliderhero img{
    width: 100%;
  }

  .acd{
    z-index: 0;
  }

  @media print {
    .noprint {
       visibility: hidden;
    }
 }

 .cityline{
    position: relative;
 }

.orgphoto{
   float: left;
   width: 200px;
}

.signuplink{
    color:#007bff !important;
}

 .orgphoto{
    padding-right: 5px;
    padding-bottom: 5px;
 }

.head_column_fixed{
     background-color: #061ec9;
    padding: 30px;
    color: #ffff;
    font-size: 16px;
    font-weight: 600;
}

.entry_column_fixed{
    padding: 30px;
    color: #061ec9;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #061ec9;
    border-left: 1px solid #061ec9;
}
.compare_fixed_column{
    width: 50%;
}
.compare_scroll_column{
    width: 50%;
    overflow-y: scroll;
}

.flex_column_scroll{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.scrollable_items{
    float: left;
    height: 100%;
    padding: 5px;
}

.name_column_scroll{
    padding: 30px;
    color: #061ec9;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #061ec9;
    border-left: 1px solid #061ec9;
}

.item_column_scroll{
    padding: 30px;
    color: #061ec9;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #061ec9;
    border-left: 1px solid #061ec9;
}


/* -------------------------------- 

Main Components 

-------------------------------- */
.cd-products-comparison-table {
    margin-bottom: 6em;
  }
  .cd-products-comparison-table::after {
    /* never visible - this is used in jQuery to check the current MQ */
    display: none;
    content: 'mobile';
  }
  .cd-products-comparison-table header {
    padding: 0 5% 25px;
  }
  .cd-products-comparison-table header::after {
    clear: both;
    content: "";
    display: table;
  }
  .cd-products-comparison-table h2 {
    float: left;
    font-weight: bold;
  }
  .cd-products-comparison-table .actions {
    float: right;
  }
  .cd-products-comparison-table .reset, .cd-products-comparison-table .filter {
    font-size: 1.4rem;
  }
  .cd-products-comparison-table .reset {
    color: #404042;
    text-decoration: underline;
  }
  .cd-products-comparison-table .filter {
    padding: .6em 1.5em;
    color: #ffffff;
    background-color: #cccccc;
    border-radius: 3px;
    margin-left: 1em;
    cursor: not-allowed;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .cd-products-comparison-table .filter.active {
    cursor: pointer;
    background-color: #9dc997;
  }
  .no-touch .cd-products-comparison-table .filter.active:hover {
    background-color: #a7cea1;
  }
  @media only screen and (min-width: 1170px) {
    .cd-products-comparison-table {
      margin-bottom: 8em;
    }
    .cd-products-comparison-table::after {
      /* never visible - this is used in jQuery to check the current MQ */
      content: 'desktop';
    }
    .cd-products-comparison-table header {
      padding: 0 5% 40px;
    }
    .cd-products-comparison-table h2 {
      font-size: 2.4rem;
    }
    .cd-products-comparison-table .reset, .cd-products-comparison-table .filter {
      font-size: 1.6rem;
    }
    .cd-products-comparison-table .filter {
      padding: .6em 2em;
      margin-left: 1.6em;
    }
  }
  
  .cd-products-table {
    position: relative;
    height: 100%;
    
  }
  
  .cd-products-table .features {
    /* fixed left column - product properties list */
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 160px;
    border-style: solid;
    border-color: blue;
    border-top-width: 1px;
    border-right: none;
    border-bottom: none;
    border-left-width: 1px;
    border-bottom-width: 1px;
    background-color: #fafafa;
    opacity: .95;
  }
  
  @media only screen and (min-width: 1170px) {
    .cd-products-table .features {
      width: 210px;
    }
  }
  
  .cd-products-table.scrolling .features::after {
    opacity: 1;
  }
  
  .cd-products-wrapper {
    overflow-x: auto;
    /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
    -webkit-overflow-scrolling: touch;
    border-style: solid;
    border-color: #e6e6e6;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  
  .cd-products-columns {
    /* products list wrapper */
    width: 604px;
    margin-left: 160px;
  }
  .cd-products-columns::after {
    clear: both;
    content: "";
    display: table;
  }
  @media only screen and (min-width: 1170px) {
    .cd-products-columns {
      width: 2480px;
      margin-left: 210px;
    }
  }
  
  .cd-products-columns .product {
    position: relative;
    float: left;
    /*width: 200px;*/
    width: 170px;
    text-align: center;
    -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, visibility 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  }
  .filtering .cd-products-columns .product:not(.selected) {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  .no-product-transition .cd-products-columns .product.selected {
    -webkit-transition: opacity 0.3s, visibility 0.3s;
    -moz-transition: opacity 0.3s, visibility 0.3s;
    transition: opacity 0.3s, visibility 0.3s;
  }
  .filtered .cd-products-columns .product:not(.selected) {
    position: absolute;
  }
  @media only screen and (min-width: 1170px) {
    .cd-products-columns .product {
      width: 310px;
    }
  }
  
    .cd-features-list li {
    font-size: 16px;
    font-weight: 500;
    padding: 25px 40px;
    border-color: #000;
    border-style: solid;
    border-top-width: 1px;
    border-left: none;
    height: 85px;
    border-bottom: none;
    border-right-width: 1px;
    }
  .cd-features-list li.rate {
    /* rating stars */
    padding: 21px 0;
  }
  .cd-features-list li.rate span {
    display: inline-block;
    height: 22px;
    width: 110px;
    
    color: transparent;
  }
  @media only screen and (min-width: 1170px) {
    .cd-features-list li {
      font-size: 1.6rem;
    }
    .cd-features-list li.rate {
      padding: 22px 0;
    }
  }
  
  .features .cd-features-list li,
  .cd-products-table .features .top-info {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    padding: 25px 10px;
    text-align: left;
  }
  @media only screen and (min-width: 1170px) {
    .features .cd-features-list li,
    .cd-products-table .features .top-info {
      text-transform: uppercase;
      line-height: 16px;
      padding: 25px 20px;
      letter-spacing: 1px;
    }
  }
  
  .features .cd-features-list li {
    /* truncate text with dots */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 85px;
    color: blue;
    text-transform: uppercase;
  }
  
  .cd-products-table .top-info {
    position: relative;
    height: 85px;
    width: 100%;
    text-align: center;
   
    border-color: blue;
    border-right:none;
    border-left-width: 1px;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    transition: height 0.3s;
    cursor: pointer;
    background: #ffffff;
  }
   
  .cd-products-table .top-info h3 {
    padding: 14px;
    font-size: 16px;
    color: blue;
    font-weight: 600;
    height: 100%;
    border:1px solid #000;
    border-top-width: 1px;
    border-bottom: none;
  }
  .cd-products-table .top-info img {
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .cd-products-table .top-info h3, .cd-products-table .top-info img {
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }
  .cd-products-table .top-info .check {
    position: relative;
    display: inline-block;
    height: 16px;
    width: 16px;
    margin: 0 auto 1em;
  }
  .cd-products-table .top-info .check::after, .cd-products-table .top-info .check::before {
    /* used to create the check icon and green circle dot - visible when product is selected */
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: 100%;
    width: 100%;
  }
  .cd-products-table .top-info .check::before {
    /* green circle dot */
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    background: #ffffff;
    -webkit-transition: background-color 0.3s, -webkit-transform 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, -moz-transform 0.3s, border-color 0.3s;
    transition: background-color 0.3s, transform 0.3s, border-color 0.3s;
  }
  .cd-products-table .top-info .check::after {
    /* check icon */
   
    background-size: 24px 24px;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  @media only screen and (min-width: 1170px) {
    .cd-products-table .top-info {
      height: 280px;
      width: 310px;
    }
    .cd-products-table .top-info h3 {
      padding-top: 1.4em;
      font-size: 1.6rem;
    }
    .cd-products-table .top-info .check {
      margin-bottom: 1.5em;
    }
  }
  
  .cd-products-table .features .top-info {
    /* models */
    width: auto;
    cursor: auto;
    background: blue;
     color: #fff;
    text-align: left;
    font-size: 18px;
  }

  .bgbluefontwhite{
    background-color: #2A487A !important;
    color:#fff !important;
  }

  .bgbluefontwhite p{
    color:#fff !important;
  }
  .pinkbtn{
    background-color: #ff5994 !important;
    border-color:#ff5994 !important;
  }

  @media only screen and (min-width: 1170px) {
    .cd-products-table .features .top-info {
      width: 210px;
    }
  }
  
  .cd-products-table .selected .top-info .check::before {
    /* green circle dot */
    background: #9dc997;
    border-color: #9dc997;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-animation: cd-bounce 0.3s;
    -moz-animation: cd-bounce 0.3s;
    animation: cd-bounce 0.3s;
  }
  
  @-webkit-keyframes cd-bounce {
    0% {
      -webkit-transform: scale(1);
    }
    60% {
      -webkit-transform: scale(1.6);
    }
    100% {
      -webkit-transform: scale(1.5);
    }
  }
  @-moz-keyframes cd-bounce {
    0% {
      -moz-transform: scale(1);
    }
    60% {
      -moz-transform: scale(1.6);
    }
    100% {
      -moz-transform: scale(1.5);
    }
  }
  @keyframes cd-bounce {
    0% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    60% {
      -webkit-transform: scale(1.6);
      -moz-transform: scale(1.6);
      -ms-transform: scale(1.6);
      -o-transform: scale(1.6);
      transform: scale(1.6);
    }
    100% {
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
  }
  .cd-products-table .selected .top-info .check::after {
    /* check icon */
    opacity: 1;
  }
  
  @media only screen and (min-width: 1170px) {
    .cd-products-table.top-fixed .cd-products-columns > li,
    .cd-products-table.top-scrolling .cd-products-columns > li,
    .cd-products-table.top-fixed .features,
    .cd-products-table.top-scrolling .features {
      padding-top: 160px;
    }
  
    .cd-products-table.top-fixed .top-info,
    .cd-products-table.top-scrolling .top-info {
      height: 160px;
      position: fixed;
      top: 0;
    }
    .no-cssgradients .cd-products-table.top-fixed .top-info, .no-cssgradients
    .cd-products-table.top-scrolling .top-info {
      border-bottom: 1px solid #e6e6e6;
    }
    .cd-products-table.top-fixed .top-info::after,
    .cd-products-table.top-scrolling .top-info::after {
      opacity: 1;
    }
    .cd-products-table.top-fixed .top-info h3,
    .cd-products-table.top-scrolling .top-info h3 {
      -webkit-transform: translateY(-116px);
      -moz-transform: translateY(-116px);
      -ms-transform: translateY(-116px);
      -o-transform: translateY(-116px);
      transform: translateY(-116px);
    }
    .cd-products-table.top-fixed .top-info img,
    .cd-products-table.top-scrolling .top-info img {
      -webkit-transform: translateY(-62px) scale(0.4);
      -moz-transform: translateY(-62px) scale(0.4);
      -ms-transform: translateY(-62px) scale(0.4);
      -o-transform: translateY(-62px) scale(0.4);
      transform: translateY(-62px) scale(0.4);
    }
  
    .cd-products-table.top-scrolling .top-info {
      position: absolute;
    }
  }
  .cd-table-navigation a {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 15px;
    -webkit-transform: translateY(55px);
    -moz-transform: translateY(55px);
    -ms-transform: translateY(55px);
    -o-transform: translateY(55px);
    transform: translateY(55px);
    /* replace text with image */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    color: transparent;
    height: 60px;
    width: 40px;
    
    border-radius: 3px;
    -webkit-transition: background-color 0.3s, opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    -moz-transition: background-color 0.3s, opacity 0.3s, visibility 0.3s, -moz-transform 0.3s;
    transition: background-color 0.3s, opacity 0.3s, visibility 0.3s, transform 0.3s;
  }
  .cd-table-navigation a.inactive {
    opacity: 0;
    visibility: hidden;
  }
  .cd-table-navigation a.prev {
    left: 120px;
    right: auto;
    -webkit-transform: translateY(55px) translateX(15px) rotate(180deg);
    -moz-transform: translateY(55px) translateX(15px) rotate(180deg);
    -ms-transform: translateY(55px) translateX(15px) rotate(180deg);
    -o-transform: translateY(55px) translateX(15px) rotate(180deg);
    transform: translateY(55px) translateX(15px) rotate(180deg);
  }
  .no-touch .cd-table-navigation a:hover {
    background-color: #404042;
  }
  @media only screen and (min-width: 1170px) {
    .cd-table-navigation a {
      -webkit-transform: translateY(100px);
      -moz-transform: translateY(100px);
      -ms-transform: translateY(100px);
      -o-transform: translateY(100px);
      transform: translateY(100px);
    }
    .cd-table-navigation a.prev {
      left: 210px;
      -webkit-transform: translateY(100px) translateX(15px) rotate(180deg);
      -moz-transform: translateY(100px) translateX(15px) rotate(180deg);
      -ms-transform: translateY(100px) translateX(15px) rotate(180deg);
      -o-transform: translateY(100px) translateX(15px) rotate(180deg);
      transform: translateY(100px) translateX(15px) rotate(180deg);
    }
    .top-fixed .cd-table-navigation a {
      position: fixed;
    }
    .top-fixed .cd-table-navigation a, .top-scrolling .cd-table-navigation a {
      -webkit-transform: translateY(45px);
      -moz-transform: translateY(45px);
      -ms-transform: translateY(45px);
      -o-transform: translateY(45px);
      transform: translateY(45px);
    }
    .top-fixed .cd-table-navigation a.prev, .top-scrolling .cd-table-navigation a.prev {
      -webkit-transform: translateY(45px) translateX(15px) rotate(180deg);
      -moz-transform: translateY(45px) translateX(15px) rotate(180deg);
      -ms-transform: translateY(45px) translateX(15px) rotate(180deg);
      -o-transform: translateY(45px) translateX(15px) rotate(180deg);
      transform: translateY(45px) translateX(15px) rotate(180deg);
    }
  }
  
  /* -------------------------------- 
  
  No JS
  
  -------------------------------- */
  .no-js .actions {
    display: none;
  }
  
  .no-js .cd-products-table .top-info {
    height: 145px;
  }
  @media only screen and (min-width: 1170px) {
    .no-js .cd-products-table .top-info {
      height: 248px;
    }
  }
  
  .no-js .cd-products-columns .check {
    display: none;
  }
  

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
}

.columtitle{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.downloadBtn{
    width: 100%;
}
.placeright{
    text-align: right;
}

.newdownloadbtn{
    background-color: dimgray;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.new-login-left{
    margin-top: 50%;
    margin-bottom: 50%;
}

.login-logo .login-content-inner{
    margin-top: 30%;
    margin-left: 50%;
    margin-right: 50%;
    width: 100%;
    text-align: center;
}


.blog-author-img {
    border-radius: 0 !important;
    height: auto !important;
    width: 100px!important;
}

.printonly{
    display: none;
}

.articleblock.maincontentblock h3 {
    font-size: 18px;
}


.articleblock.maincontentblock h4 {
    font-size: 16px;
}
.open .inner{
    display: block;
}

.fullsize_content_block{
    background-color: white;
}

@media print {
    .printonly {display: block;}
 }

.filtermenu{
    margin-top: 60px;
}

.minih1section img{
    width: 100%;
}